import React from 'react'
import {ReactComponent as Openscreenlogo} from "../Images/Icons/Logo_Header.svg"

const Header = () => {
  return (
    // <div className='w-full flex flex-row-reverse  ' style={{backgroundImage:"linear-gradient(90deg, #E72144 0%, #76549E 99.94%)",opacity: 0.05}}>            
    <div className='w-full h-[10%] flex flex-row-reverse  ' style={{background: "linear-gradient(to right,rgba(231, 33, 68, 0.05), rgba(118, 84, 158, 0.05))"}}>            
            {/* <Openscreenlogo className=' w-[43px] sm:w-[80px] md:w-[200px] absolute top-3 right-3' /> */}
            {/* <Openscreenlogo className=' w-[8vh] sm:w-[80px] md:w-[100px] p-2' /> */}
            <Openscreenlogo className=' w-[8vh]  p-2' />
        </div>
  )
}

export default Header