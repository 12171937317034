import React, { useEffect , useState } from 'react'
import Trainings_List from '../Components/Trainings_List'
import filter from "../Images/filter.svg"
import {  useParams } from 'react-router-dom'
import axios from 'axios'
import useAuth from '../Hook/useAuth'
import Popup_practice from '../Components/Popup_practice'

const Practice = () => {

  const [practics , SetPractics ]  = useState({})
  const [nullpractics , SetnullPractics ]  = useState(false)
  const {levelId} = useParams()
  console.log(levelId)
  const { auth , logout , setAuth } = useAuth()

  useEffect ( ( ) => 
  { 
    ChoosePra()
  } , [])
 
  
  const ChoosePra = async() => 
  {
    
      if(auth)
      {

      await axios.get(`function_patient/practices/${levelId}` ,
          { 
          headers: { 'Content-Type': 'application/json'}
          } ,
          {'Access-Control-Allow-Credentials': true}).then(res => 
          {
          if(res?.status === 200)
          {
             
              SetPractics(res?.data)
              
              console.log(res?.data)
              if(Object.keys(res?.data).length==0)
              {
                SetnullPractics(true)
              }
              
              // const p = res.data
              // Object.keys(p).map((key)=>
              // {
              //     console.log(key)

              // })
              // Setdata(res.data)
           
          }
          else{
              //navigate.replace('/login')
              // navigate('/' , {replace: true})
          }
          })
      .catch(err => {
          console.log("err")
          //logoutFun()
      })
      }
      else{
          //logoutFun()
          logout()
      }
  }

  const arrBgs={
    // 1:"linear-gradient(180deg, #6457A0 38.46%, #A979B0 75.87%, #F5F5F5 97.63%)",
    1:"linear-gradient(rgba(100, 87, 160, 0.34), rgba(169, 121, 176, 0.34), rgba(245, 245, 245, 0.34))",
    // 2:"linear-gradient(180deg, #66539E 38.46%, #4C8ECA 75.87%, #F5F5F5 97.63%)",
    2:"linear-gradient(rgba(102, 83, 158, 0.34), rgba(76, 142, 202, 0.34), rgba(245, 245, 245, 0.34))",
    // 3:"linear-gradient(180deg, #43A1CE 38.42%, #5BBA9F 75.86%, #F5F5F5 97.63%)",
    3:"linear-gradient(rgba(67, 161, 206, 0.34), rgba(91, 186, 159, 0.34), rgba(245, 245, 245, 0.34))",
    // 4:"linear-gradient(180deg, #EA2C69 38.42%, #F07B35 75.86%, #F5F5F5 97.63%)",
    4:"linear-gradient(rgba(234, 44, 105, 0.34), rgba(240, 123, 53, 0.34), rgba(245, 245, 245, 0.34))",
    // 5:"linear-gradient(180deg, #F58A45 38.37%, #FCB73D 75.86%, #F5F5F5 97.63%)"}
    5:"linear-gradient(rgba(245, 138, 69, 0.34), rgba(252, 183, 61, 0.34), rgba(245, 245, 245, 0.34))"}
 
  return (
    
    <div className='h-full flex flex-col overflow-y-auto ' style={{background:arrBgs[levelId]}}>
      {/* <Header/> */}
     <div className='flex flex-col'>
      <div className='flex flex-row-reverse  '>
          {/* <h1 className='flex justify-center items-center w-full'>תירגולים</h1> */}
          <h1 className='flex justify-center items-center w-full ont-Hebbo font-bold text-3xl text-black mt-3'>תרגולים</h1>
          <img className='w-12 h-12' src={filter} alt="xx" />
      </div>
      
      <div className=''>
      <div class=" h-fit">
      
      
      
      <div className='p-2'>
        
      {Object.keys(practics)?.map((row)=>(
      <Trainings_List item={{"main_title":row,"info_videos":practics[row]}} levelId={levelId}/>))}
      {/* console.log(row,practics[row]) */}
      {/* ))} */}
        
        
        
        
        {/* <Trainings_List Video="Vidoe1" main_title="מומלצים בשבילך"/>
         <Trainings_List main_title="נשימה"/>
         <Trainings_List/>
         <Trainings_List/> */}
         
       </div>
       {nullpractics&&<Popup_practice/>}
       </div>
    </div>
    </div>
    {/* <Nav num="4"/> */}
    </div>
    
  )
}

export default Practice