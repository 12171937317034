import React from 'react'
import Play_stage1 from "../Images/Arrows/Play_stage1.svg"
import Play_stage2 from "../Images/Arrows/Stage2-Play.svg"
import Play_stage3 from "../Images/Arrows/Stage3-Play.svg"
import Play_stage4 from "../Images/Arrows/Stage4-Play.svg"
import Play_stage5 from "../Images/Arrows/Stage5-Play.svg"
import IconPractice from "../Images/Icons/practice2_sm.svg"
import IconGuild from "../Images/Icons/training_sm.svg"
import Icon_lock from "../Images/Icons/icon_lock.svg"
import GreenV from "../Images/Icons/GreenV.svg"
import  {useHistory , useLocation }  from "react-router-dom";
import axios from 'axios'

const Action = ({techname ,item,index}) => {
  const arrPlaystages={1:Play_stage1,2:Play_stage2,3:Play_stage3,4:Play_stage4,5:Play_stage5}

  const navigate = useHistory()
  const onSubmit = (e) =>
  {
    e.preventDefault()
    RouterFrameFun()
  }

  
  const RouterFrameFun = async() =>{

    // we need create a exirces.. 
    await axios.post('function_patient/build_exercise' , 
    JSON.stringify({actionId : item.actionId }) , 
    { 
      headers: { 'Content-Type': 'application/json'}
    } ,
    {'Access-Control-Allow-Credentials': true})
      .then((res) => {
      if(res?.status === 200)
       {
        localStorage.setItem('type' , 1) 
        if(item.type === 'G')
        {
            console.log("actionId:" +  item.actionId)
            const actionIdRes = res?.data?.actionId
            const urlVideo =  res?.data?.urlVideo
            const urlImage =  res?.data?.urlImage
            const description =  res?.data?.description

            //save a data on storage 

            if((actionIdRes != null)&&(urlVideo!= null)&&(urlImage != null))
            {
              localStorage.setItem('techName' ,techname)
              localStorage.setItem('levelId' ,index)
              localStorage.setItem('urlVideo' ,urlVideo)
              localStorage.setItem('urlImage' ,urlImage)
              localStorage.setItem('description' ,description)
              localStorage.setItem('success' ,false)
              localStorage.setItem('TimeVideo' ,0)

              navigate.push(`/GuildFrame/${actionIdRes}`);
            }
        }
        else
        {
            console.log("actionId:" +  item.actionId)
            const execiserId = res?.data?.exercise_id
            const urlVideo =  res?.data?.urlVideo
            const urlImage =  res?.data?.urlImage
            const description =  res?.data?.description

            if((execiserId != null)&&(urlVideo!= null)&&(urlImage != null))
            {
              localStorage.setItem('techName' ,techname)
              localStorage.setItem('levelId' ,index)
              localStorage.setItem('urlVideo' ,urlVideo)
              localStorage.setItem('urlImage' ,urlImage)
              localStorage.setItem('description' ,description)
              localStorage.setItem('position' ,"q1")
              localStorage.setItem('execiserId' ,execiserId)
              navigate.push(`/ActionFrame/${execiserId}`);
            }
        }
       }
       else{
          if(res.response?.status === 500)
           {
              console.log(res?.data)
           }
       }
    })
      .catch( (err) => 
          {
            
            // if(err.response?.status === 401)
            // {
            //   logoutFun()
            // }
          })
  }

console.log(item)
console.log(item.status)
  return (
    // <div className='w-full h-[45px] bg-white flex flex-row justify-between items-center '>  
    <div className='w-full h-fit bg-white flex flex-row justify-between items-center ' onClick={onSubmit}>  
    <img className='w-[25px] mx-4' src={arrPlaystages[index]} alt="sda" />
      {/*onClick={()=>{SetHide(prev=>!prev)}}  */}
   <p className='w-full text-xl text-right ' >{item.nameAction}</p>
   {
  
  //  item.status=="Finish"?<img className='w-[17px] mr-3' src={GreenV} alt="sda"/> :
   <img className={item.type=="G"?item.status=="Finish"?'w-[35px] mx-2 p-[1px] rounded-lg border-green-400 border-2':'w-[33px] mx-3':item.status=="Lock"?'w-[27px] mx-3':'w-[33px] mx-3'} src={item.type=="G"?IconGuild:item.status=="Lock"?Icon_lock:IconPractice} alt="sda"/> }
   </div>   
  )
}

export default Action