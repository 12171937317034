import logo from './logo.svg';
import Header from './Components/Header';
import Dashboard from './Pages/Dashboard'; 
import VideoBig from './Components/VideoBig';
import './App.css';
import OpenScreenB from './Pages/OpenScreen.jsx'
import Home from './Pages/Home.jsx'
import Practice from './Pages/Practice';
import Tutorilias from './Pages/Tutorilias';
import { BrowserRouter as Router,Route,Link,Switch,Redirect} from 'react-router-dom';
import QA_2 from './Components/QA_2';
import Nav from './Components/Nav';
import Profile from './Pages/Profile';
import Login from './Pages/Login';
import { useState,useEffect } from 'react'
import ApiRequest from './Components/ApiRequest';
import Map_Practice from './Pages/Map_Practice';
import useAuth from '../src/Hook/useAuth'
import ActionFrame from './Components/ActionFrame';
import GuildFrame from './Components/GuildFrame';
import { QueryClient, QueryClientProvider } from "react-query";
import About from './Pages/About.jsx';

function App() {
  
  useEffect(() => {
    // Attempt to make the app full-screen on load
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    }
  }, []);
  const [isloading , setIsLoading] = useState(true)
  const { auth , logout , setAuth } = useAuth()
  useEffect(()=>
  {
    setTimeout(()=>
    {
      setIsLoading(false)
    } , 2000)


  },[])

  const queryClient = new QueryClient({});

  return (

    <QueryClientProvider client={queryClient}>
   
    {/* <div className={isloading?'':"hidden"}>
      <OpenScreenB />
      </div> */}
      
      {isloading?<OpenScreenB />:
   
    
    <div className={isloading?"hidden":"h-full flex flex-col "}>
    <Router>
    <Header className=""/>
    {/* <div className="App h-full flex flex-col" > */}
    
      {/* <OpenScreenB/> */}
      {/* <Home/> */}
      {/* <Practice/> */}
      
      <Switch >
          <Route path="/Dashboard">
              <Dashboard/>
            </Route>
            
            <Route path="/Map">
              <Map_Practice />
           </Route>
           <Route path="/Practice/:levelId">
              <Practice/>
           </Route>
           <Route path="/Profile">
              <Profile/>
           </Route>
           <Route path="/About">
              <About/>
           </Route>

           {/* About */}
          
          
          <Route path="/Login">
              <Login/>
          </Route>
          <Route path="/Tutorilias">
              <Tutorilias />
          </Route>
          <Route path="/QA_2">
              <QA_2/>
          </Route>
          <Route path="/VideoBig">
              <VideoBig/>
          </Route>
          <Route path="/Home">
              <Home/>
          </Route>
          <Route path="/ActionFrame/:execiserId/">
              <ActionFrame/>
           </Route>
           <Route path="/GuildFrame/:actionId/">
              <GuildFrame/>
           </Route>


           <Route path="**" >
              <Redirect to="/login"/>
          </Route>



           <Route path="/">
              <Login/>
          </Route>
        </Switch>
        {auth != null?
      <Nav />:<></>}
      </Router>
      </div>
}
    
    
    </QueryClientProvider>
    
  );
}

export default App;




{/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}