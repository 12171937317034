import React, { useEffect } from 'react'
import Sel from "../Images/Stages_lock/Stage1_SelectTitle.svg"
import { useState } from 'react'
import Arrow_Whit_right from "../Images/Arrows/arrow_Whit-right.svg"
import Arrow_Whit_down from "../Images/Arrows/arrow_White-down.svg"
import SubPractice from './SubPractice'
import Stage1 from "../Images/Stages_lock/Stage1.svg"
import icon_v from "../Images/Icons/icon_v.svg"


const Stage = ({item,index}) => {
  const stagesColor={0:"linear-gradient(73.19deg, #AC7BB1 0.05%, #A477AF 9.64%, #7660A4 68.24%, #6457A0 97.65%)",
  1:"linear-gradient(73.19deg, #09090B 0.05%, #F1872D 0.13%, #4995CF 0.56%, #67509C 97.25%)",
    2:"linear-gradient(73.19deg, #5DBC9C 0.15%, #3F9DD6 97.28%)",
    3:"linear-gradient(73.19deg, #F1872D 0.13%, #E81776 97.26%)",4:"linear-gradient(73.19deg, #FFCA3B 0.05%, #F37C47 97.47%)"}
  const [hide,SetHide]=useState(false);


  useEffect(()=>
  {
    if(localStorage.getItem("levelId") != null)
    {
      const ind =  localStorage.getItem("levelId")
      if(ind == (index + 1))
      {  
        SetHide(true)
        localStorage.removeItem("levelId")
        localStorage.removeItem("type")
        localStorage.removeItem("urlImage")
        localStorage.removeItem("urlVideo")
        localStorage.removeItem("description")
      }
    }
  } , [] )


  //console.log(item)



  return (
    <div className='bg-white  rounded-2xl flex flex-col m-2 p-3  '>
    {/* <div className='rounded-2xl flex flex-row justify-between items-center border border-b-4  py-4' style={{background: "linear-gradient(73.19deg, #AC7BB1 0.05%, #A477AF 9.64%, #7660A4 68.24%, #6457A0 97.65%)"}}> */}
    <div className={hide?"flex flex-row justify-between items-center   py-4 rounded-t-2xl":'rounded-2xl flex flex-row justify-between items-center   py-4 '} style={{background: stagesColor[index]}}>
      {/* <img className='w-[10px] mx-4' src={item.arrow} alt="sda" onClick={()=>{SetHide(!hide)}}/>   */}
      <img className='w-[10px] mx-4' src={!hide? Arrow_Whit_right :Arrow_Whit_down} alt="sda" onClick={()=>{SetHide(prev=>!prev)}}/>  
    <p className='text-white text-xl text-center'>{item.nameOfLevel}</p>
    {item.status=="Finish"?<img className='w-[50px] mr-2' src={icon_v} alt="sda"/>:
    <div className  ='w-[50px] mr-2'></div>
      }
    
    </div>
    <div className={hide?"w-full flex flex-col justify-between items-center border-0  divide-y-2 ":'hidden '}>
    {item.tutorialsTeches?.map((Teche)=>{return(
    <SubPractice item={Teche} index={index}/>)})}
    {/* <SubPractice item={item}/> */}
   
    
     
      {/* { stages.map( (stage,index) =>
       {
        return(
            

            (index < ind)? <Stage item={stage}/> : <Stage_Luck item={stage} />
        )

       })
       } */}
      
    </div>
    </div>
  )
}

export default Stage