import React from 'react'
import { useState } from 'react';

const ScaleV = ({col,setcol}) => {
    // const [index,SetIndex]=useState(-1)
  return (
    // <div className='h-full flex justify-center   '>
<svg  width={"10vw"}  viewBox="0 0 30 370" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_960_2891)">
<path d="M12.2955 335.526L12.2955 34.9766C12.2955 32.8934 14.0602 31.189 16.2171 31.189C18.374 31.189 20.1387 32.8934 20.1387 34.9766L20.1387 335.526C20.1387 337.609 18.374 339.313 16.2171 339.313C14.0602 339.313 12.2955 337.647 12.2955 335.526Z" fill="url(#paint0_linear_960_2891)"/>
<path d="M8.00156 355.145L8.00156 12.8945C8.00156 9.22047 11.0604 6.26611 14.8643 6.26611C18.6682 6.26611 21.7271 9.25835 21.7271 12.8945L21.7271 355.145C21.7271 358.819 18.6682 361.774 14.8643 361.774C11.0604 361.774 8.00156 358.819 8.00156 355.145Z" fill="url(#paint1_linear_960_2891)"/>
<path d="M9.06037 13.6141C9.06037 16.7938 11.7291 19.3713 15.0212 19.3713C18.3132 19.3713 20.9819 16.7938 20.9819 13.6141C20.9819 10.4345 18.3132 7.85693 15.0212 7.85693C11.7291 7.85693 9.06037 10.4345 9.06037 13.6141Z" fill="black" stroke="white" stroke-width="2" stroke-miterlimit="10"onClick={()=>{setcol(10)}}/>
<path d="M8.98175 47.7028C8.98175 50.8824 11.6505 53.46 14.9425 53.46C18.2346 53.46 20.9033 50.8824 20.9033 47.7028C20.9033 44.5231 18.2346 41.9456 14.9425 41.9456C11.6505 41.9456 8.98175 44.5231 8.98175 47.7028Z" fill="black" stroke="white" stroke-width="2" stroke-miterlimit="10"onClick={()=>{setcol(9)}}/>
<path d="M8.98175 81.7916C8.98175 84.9713 11.6505 87.5488 14.9425 87.5488C18.2346 87.5488 20.9033 84.9713 20.9033 81.7916C20.9033 78.612 18.2346 76.0344 14.9425 76.0344C11.6505 76.0344 8.98175 78.612 8.98175 81.7916Z" fill="black" stroke="white" stroke-width="2" stroke-miterlimit="10"onClick={()=>{setcol(8)}}/>
<path d="M8.98175 115.842C8.98175 119.022 11.6505 121.599 14.9425 121.599C18.2346 121.599 20.9033 119.022 20.9033 115.842C20.9033 112.663 18.2346 110.085 14.9425 110.085C11.6505 110.085 8.98175 112.663 8.98175 115.842Z" fill="black" stroke="white" stroke-width="2" stroke-miterlimit="10"onClick={()=>{setcol(7)}}/>
<path d="M8.98175 149.931C8.98175 153.111 11.6505 155.688 14.9425 155.688C18.2346 155.688 20.9033 153.111 20.9033 149.931C20.9033 146.752 18.2346 144.174 14.9425 144.174C11.6505 144.174 8.98175 146.752 8.98175 149.931Z" fill="black" stroke="white" stroke-width="2" stroke-miterlimit="10"onClick={()=>{setcol(6)}}/>
<path d="M8.98175 184.02C8.98175 187.2 11.6505 189.777 14.9425 189.777C18.2346 189.777 20.9033 187.2 20.9033 184.02C20.9033 180.84 18.2346 178.263 14.9425 178.263C11.6505 178.263 8.98175 180.84 8.98175 184.02Z" fill="black" stroke="white" stroke-width="2" stroke-miterlimit="10"onClick={()=>{setcol(5)}}/>
<path d="M8.98175 218.072C8.98175 221.252 11.6505 223.83 14.9425 223.83C18.2346 223.83 20.9033 221.252 20.9033 218.072C20.9033 214.893 18.2346 212.315 14.9425 212.315C11.6505 212.315 8.98175 214.893 8.98175 218.072Z" fill="black" stroke="white" stroke-width="2" stroke-miterlimit="10"onClick={()=>{setcol(4)}}/>
<path d="M8.98175 252.16C8.98175 255.339 11.6505 257.917 14.9425 257.917C18.2346 257.917 20.9033 255.339 20.9033 252.16C20.9033 248.98 18.2346 246.402 14.9425 246.402C11.6505 246.402 8.98175 248.98 8.98175 252.16Z" fill="black" stroke="white" stroke-width="2" stroke-miterlimit="10"onClick={()=>{setcol(3)}}/>
<path d="M8.98175 286.248C8.98175 289.428 11.6505 292.005 14.9425 292.005C18.2346 292.005 20.9033 289.428 20.9033 286.248C20.9033 283.069 18.2346 280.491 14.9425 280.491C11.6505 280.491 8.98175 283.069 8.98175 286.248Z" fill="black" stroke="white" stroke-width="2" stroke-miterlimit="10"onClick={()=>{setcol(2)}}/>
<path d="M8.98175 320.298C8.98175 323.478 11.6505 326.055 14.9425 326.055C18.2346 326.055 20.9033 323.478 20.9033 320.298C20.9033 317.118 18.2346 314.541 14.9425 314.541C11.6505 314.541 8.98175 317.118 8.98175 320.298Z" fill="black" stroke="white" stroke-width="2" stroke-miterlimit="10"onClick={()=>{setcol(1)}}/>
<path d="M8.98175 354.389C8.98175 357.569 11.6505 360.147 14.9425 360.147C18.2346 360.147 20.9033 357.569 20.9033 354.389C20.9033 351.21 18.2346 348.632 14.9425 348.632C11.6505 348.632 8.98175 351.21 8.98175 354.389Z" fill="black" stroke="white" stroke-width="2" stroke-miterlimit="10"onClick={()=>{setcol(0)}}/>
</g>
{col==0?<g clip-path="url(#clip1_960_2891)">
<path d="M15.0002 369.925C6.74263 369.925 0.0380859 363.237 0.0380859 355C0.0380859 346.763 6.74263 340.076 15.0002 340.076C23.2578 340.076 29.9623 346.763 29.9623 355C29.9623 363.237 23.2578 369.925 15.0002 369.925ZM15.0002 341.587C7.57597 341.587 1.55324 347.595 1.55324 355C1.55324 362.406 7.57597 368.413 15.0002 368.413C22.4245 368.413 28.4472 362.406 28.4472 355C28.4472 347.595 22.4245 341.587 15.0002 341.587Z" fill="white"/>
<path d="M15.1516 341.851C7.84101 341.851 1.89404 347.783 1.89404 355.075C1.89404 362.368 7.84101 368.3 15.1516 368.3C22.4622 368.3 28.4092 362.368 28.4092 355.075C28.4092 347.746 22.4622 341.851 15.1516 341.851Z" fill="url(#paint2_linear_960_2891)"/>
<path d="M20.2273 355.113L20.0379 356.549C20.0379 359.383 17.7273 361.688 14.8864 361.688C12.0455 361.688 9.73486 359.383 9.73486 356.549L9.77274 355" stroke="#1A1A1A" stroke-width="3" stroke-miterlimit="10"/>
<path d="M10.4922 352.242C11.078 352.242 11.5529 351.768 11.5529 351.184C11.5529 350.6 11.078 350.126 10.4922 350.126C9.90649 350.126 9.43164 350.6 9.43164 351.184C9.43164 351.768 9.90649 352.242 10.4922 352.242Z" fill="black"/>
<path d="M18.5982 352.242C19.184 352.242 19.6588 351.768 19.6588 351.184C19.6588 350.6 19.184 350.126 18.5982 350.126C18.0124 350.126 17.5376 350.6 17.5376 351.184C17.5376 351.768 18.0124 352.242 18.5982 352.242Z" fill="black"/>
</g>:""}
{col==1?<g clip-path="url(#clip2_960_2891)">
<path d="M15.0004 333.169C22.8454 333.169 29.205 326.825 29.205 319C29.205 311.175 22.8454 304.831 15.0004 304.831C7.15549 304.831 0.795898 311.175 0.795898 319C0.795898 326.825 7.15549 333.169 15.0004 333.169Z" fill="url(#paint3_linear_960_2891)"/>
<path d="M15.0002 333.925C6.74263 333.925 0.0380859 327.237 0.0380859 319C0.0380859 310.763 6.74263 304.076 15.0002 304.076C23.2578 304.076 29.9623 310.763 29.9623 319C29.9623 327.237 23.2578 333.925 15.0002 333.925ZM15.0002 305.587C7.57597 305.587 1.55324 311.595 1.55324 319C1.55324 326.406 7.57597 332.413 15.0002 332.413C22.4245 332.413 28.4472 326.406 28.4472 319C28.4472 311.595 22.4245 305.587 15.0002 305.587Z" fill="white"/>
<path d="M14.8865 325.348C11.4017 325.348 8.59863 322.552 8.59863 319.076H10.1138C10.1138 321.683 12.2729 323.836 14.8865 323.836C17.5001 323.836 19.6592 321.683 19.6592 319.076H21.1744C21.1744 322.514 18.3714 325.348 14.8865 325.348Z" fill="#1A1A1A"/>
<path d="M10.1515 315.977C10.7791 315.977 11.2879 315.47 11.2879 314.844C11.2879 314.218 10.7791 313.71 10.1515 313.71C9.5239 313.71 9.01514 314.218 9.01514 314.844C9.01514 315.47 9.5239 315.977 10.1515 315.977Z" fill="black"/>
<path d="M18.8634 315.977C19.491 315.977 19.9998 315.47 19.9998 314.844C19.9998 314.218 19.491 313.71 18.8634 313.71C18.2358 313.71 17.7271 314.218 17.7271 314.844C17.7271 315.47 18.2358 315.977 18.8634 315.977Z" fill="black"/>
</g>:""}
{col==2?<g clip-path="url(#clip3_960_2891)">
<path d="M15.0004 300.169C22.8454 300.169 29.205 293.825 29.205 286C29.205 278.175 22.8454 271.831 15.0004 271.831C7.15549 271.831 0.795898 278.175 0.795898 286C0.795898 293.825 7.15549 300.169 15.0004 300.169Z" fill="url(#paint4_linear_960_2891)"/>
<path d="M15.0002 300.925C6.74263 300.925 0.0380859 294.237 0.0380859 286C0.0380859 277.763 6.74263 271.076 15.0002 271.076C23.2578 271.076 29.9623 277.763 29.9623 286C29.9623 294.237 23.2578 300.925 15.0002 300.925ZM15.0002 272.587C7.57597 272.587 1.55324 278.595 1.55324 286C1.55324 293.406 7.57597 299.413 15.0002 299.413C22.4245 299.413 28.4472 293.406 28.4472 286C28.4472 278.595 22.4245 272.587 15.0002 272.587Z" fill="white"/>
<path d="M14.9622 291.932C12.197 291.932 9.69703 290.081 8.93945 287.398L10.3788 286.982C10.9849 288.985 12.841 290.421 14.9622 290.421C17.0834 290.421 18.9773 289.023 19.5455 286.982L20.9849 287.398C20.2273 290.081 17.7652 291.932 14.9622 291.932Z" fill="#1A1A1A"/>
<path d="M10.1139 282.977C10.7415 282.977 11.2503 282.47 11.2503 281.844C11.2503 281.218 10.7415 280.71 10.1139 280.71C9.48631 280.71 8.97754 281.218 8.97754 281.844C8.97754 282.47 9.48631 282.977 10.1139 282.977Z" fill="black"/>
<path d="M18.8258 282.977C19.4534 282.977 19.9622 282.47 19.9622 281.844C19.9622 281.218 19.4534 280.71 18.8258 280.71C18.1982 280.71 17.6895 281.218 17.6895 281.844C17.6895 282.47 18.1982 282.977 18.8258 282.977Z" fill="black"/>
</g>:""}
{col==3?<g clip-path="url(#clip4_960_2891)">
<path d="M15.0004 266.169C22.8454 266.169 29.205 259.825 29.205 252C29.205 244.175 22.8454 237.831 15.0004 237.831C7.15549 237.831 0.795898 244.175 0.795898 252C0.795898 259.825 7.15549 266.169 15.0004 266.169Z" fill="url(#paint5_linear_960_2891)"/>
<path d="M15.0002 266.925C6.74263 266.925 0.0380859 260.237 0.0380859 252C0.0380859 243.763 6.74263 237.076 15.0002 237.076C23.2578 237.076 29.9623 243.763 29.9623 252C29.9623 260.237 23.2578 266.925 15.0002 266.925ZM15.0002 238.587C7.57597 238.587 1.55324 244.595 1.55324 252C1.55324 259.406 7.57597 265.413 15.0002 265.413C22.4245 265.413 28.4472 259.406 28.4472 252C28.4472 244.595 22.4245 238.587 15.0002 238.587Z" fill="white"/>
<path d="M15.3409 257.025C12.9546 257.025 10.7955 255.703 9.73486 253.587L11.0985 252.907C11.9318 254.532 13.5606 255.514 15.3409 255.514C17.1591 255.514 18.7879 254.494 19.6212 252.869L20.9849 253.549C19.8864 255.665 17.7273 257.025 15.3409 257.025Z" fill="#1A1A1A"/>
<path d="M10.6442 248.977C11.2718 248.977 11.7805 248.47 11.7805 247.844C11.7805 247.218 11.2718 246.71 10.6442 246.71C10.0166 246.71 9.50781 247.218 9.50781 247.844C9.50781 248.47 10.0166 248.977 10.6442 248.977Z" fill="black"/>
<path d="M19.3561 248.977C19.9837 248.977 20.4925 248.47 20.4925 247.844C20.4925 247.218 19.9837 246.71 19.3561 246.71C18.7285 246.71 18.2197 247.218 18.2197 247.844C18.2197 248.47 18.7285 248.977 19.3561 248.977Z" fill="black"/>
</g>:""}
{col==4?<g clip-path="url(#clip5_960_2891)">
<path d="M15.0004 232.169C22.8454 232.169 29.205 225.825 29.205 218C29.205 210.175 22.8454 203.831 15.0004 203.831C7.15549 203.831 0.795898 210.175 0.795898 218C0.795898 225.825 7.15549 232.169 15.0004 232.169Z" fill="url(#paint6_linear_960_2891)"/>
<path d="M15.0002 232.925C6.74263 232.925 0.0380859 226.237 0.0380859 218C0.0380859 209.763 6.74263 203.076 15.0002 203.076C23.2578 203.076 29.9623 209.763 29.9623 218C29.9623 226.237 23.2578 232.925 15.0002 232.925ZM15.0002 204.587C7.57597 204.587 1.55324 210.595 1.55324 218C1.55324 225.406 7.57597 231.413 15.0002 231.413C22.4245 231.413 28.4472 225.406 28.4472 218C28.4472 210.595 22.4245 204.587 15.0002 204.587Z" fill="white"/>
<path d="M15.3034 222.081C13.3337 222.081 11.5156 221.212 10.3413 219.663L11.5534 218.718C12.4625 219.889 13.8262 220.569 15.3413 220.569C16.8186 220.569 18.1822 219.889 19.0913 218.756L20.2656 219.7C19.0534 221.212 17.2352 222.081 15.3034 222.081Z" fill="#1A1A1A"/>
<path d="M10.4166 214.977C11.0442 214.977 11.553 214.47 11.553 213.844C11.553 213.218 11.0442 212.71 10.4166 212.71C9.78904 212.71 9.28027 213.218 9.28027 213.844C9.28027 214.47 9.78904 214.977 10.4166 214.977Z" fill="black"/>
<path d="M19.091 214.977C19.7185 214.977 20.2273 214.47 20.2273 213.844C20.2273 213.218 19.7185 212.71 19.091 212.71C18.4634 212.71 17.9546 213.218 17.9546 213.844C17.9546 214.47 18.4634 214.977 19.091 214.977Z" fill="black"/>
</g>:""}
{col==6?<g clip-path="url(#clip6_960_2891)">
<path d="M15.0004 164.169C22.8454 164.169 29.205 157.825 29.205 150C29.205 142.175 22.8454 135.831 15.0004 135.831C7.15549 135.831 0.795898 142.175 0.795898 150C0.795898 157.825 7.15549 164.169 15.0004 164.169Z" fill="url(#paint7_linear_960_2891)"/>
<path d="M15.0002 164.925C6.74263 164.925 0.0380859 158.237 0.0380859 150C0.0380859 141.763 6.74263 135.076 15.0002 135.076C23.2578 135.076 29.9623 141.763 29.9623 150C29.9623 158.237 23.2578 164.925 15.0002 164.925ZM15.0002 136.587C7.57597 136.587 1.55324 142.595 1.55324 150C1.55324 157.406 7.57597 163.413 15.0002 163.413C22.4245 163.413 28.4472 157.406 28.4472 150C28.4472 142.595 22.4245 136.587 15.0002 136.587Z" fill="white"/>
<path d="M19.0534 153.816C18.1443 152.645 16.7807 151.965 15.2656 151.965C13.7883 151.965 12.4246 152.645 11.5156 153.778L10.3413 152.834C11.5534 151.323 13.3337 150.416 15.3034 150.416C17.2731 150.416 19.0913 151.285 20.2656 152.834L19.0534 153.816Z" fill="#1A1A1A"/>
<path d="M10.4166 146.977C11.0442 146.977 11.553 146.47 11.553 145.844C11.553 145.218 11.0442 144.71 10.4166 144.71C9.78904 144.71 9.28027 145.218 9.28027 145.844C9.28027 146.47 9.78904 146.977 10.4166 146.977Z" fill="black"/>
<path d="M19.1286 146.977C19.7561 146.977 20.2649 146.47 20.2649 145.844C20.2649 145.218 19.7561 144.71 19.1286 144.71C18.501 144.71 17.9922 145.218 17.9922 145.844C17.9922 146.47 18.501 146.977 19.1286 146.977Z" fill="black"/>
</g>:""}
{col==5?<g clip-path="url(#clip7_960_2891)">
<path d="M19.6968 170.587C8.25736 166.922 -2.12143 177.274 1.55281 188.685C2.87857 192.803 6.17402 196.091 10.3028 197.413C21.7422 201.078 32.121 190.725 28.4467 179.315C27.121 175.196 23.8255 171.909 19.6968 170.587Z" fill="url(#paint8_linear_960_2891)"/>
<path d="M21.1363 186.796H9.28027V188.307H21.1363V186.796Z" fill="#1A1A1A"/>
<path d="M10.5299 180.977C11.1575 180.977 11.6663 180.47 11.6663 179.844C11.6663 179.218 11.1575 178.71 10.5299 178.71C9.90232 178.71 9.39355 179.218 9.39355 179.844C9.39355 180.47 9.90232 180.977 10.5299 180.977Z" fill="black"/>
<path d="M19.2042 180.977C19.8318 180.977 20.3406 180.47 20.3406 179.844C20.3406 179.218 19.8318 178.71 19.2042 178.71C18.5766 178.71 18.0679 179.218 18.0679 179.844C18.0679 180.47 18.5766 180.977 19.2042 180.977Z" fill="black"/>
<path d="M19.6968 170.587C8.25736 166.922 -2.12143 177.274 1.55281 188.685C2.87857 192.803 6.17402 196.091 10.3028 197.413C21.7422 201.078 32.121 190.725 28.4467 179.315C27.121 175.196 23.8255 171.909 19.6968 170.587Z" fill="url(#paint9_linear_960_2891)"/>
<path d="M15.0376 198.924C13.4089 198.924 11.7422 198.66 10.0755 198.131C5.68158 196.733 2.23461 193.295 0.833097 188.912C-0.985085 183.282 0.378551 177.501 4.43158 173.458C8.48461 169.416 14.2801 168.055 19.924 169.869C24.3179 171.267 27.7649 174.705 29.1664 179.088C30.9846 184.718 29.621 190.499 25.5679 194.541C22.6892 197.413 18.977 198.924 15.0376 198.924ZM14.9619 170.587C11.4013 170.587 8.06795 171.947 5.49219 174.516C1.81795 178.181 0.643703 183.358 2.27249 188.458C3.52249 192.35 6.62855 195.448 10.5301 196.695C15.6058 198.32 20.8331 197.111 24.5073 193.484C28.1437 189.856 29.3558 184.642 27.727 179.541C26.477 175.65 23.371 172.552 19.4695 171.305C17.9543 170.814 16.4392 170.587 14.9619 170.587Z" fill="white"/>
<path d="M21.1363 186.796H9.28027V188.307H21.1363V186.796Z" fill="#1A1A1A"/>
<path d="M10.5299 180.977C11.1575 180.977 11.6663 180.47 11.6663 179.844C11.6663 179.218 11.1575 178.71 10.5299 178.71C9.90232 178.71 9.39355 179.218 9.39355 179.844C9.39355 180.47 9.90232 180.977 10.5299 180.977Z" fill="black"/>
<path d="M19.2042 180.977C19.8318 180.977 20.3406 180.47 20.3406 179.844C20.3406 179.218 19.8318 178.71 19.2042 178.71C18.5766 178.71 18.0679 179.218 18.0679 179.844C18.0679 180.47 18.5766 180.977 19.2042 180.977Z" fill="black"/>
</g>:""}
{col==7?<g clip-path="url(#clip8_960_2891)">
<path d="M15.0004 130.169C22.8454 130.169 29.205 123.825 29.205 116C29.205 108.175 22.8454 101.831 15.0004 101.831C7.15549 101.831 0.795898 108.175 0.795898 116C0.795898 123.825 7.15549 130.169 15.0004 130.169Z" fill="url(#paint10_linear_960_2891)"/>
<path d="M15.0002 130.925C6.74263 130.925 0.0380859 124.237 0.0380859 116C0.0380859 107.763 6.74263 101.076 15.0002 101.076C23.2578 101.076 29.9623 107.763 29.9623 116C29.9623 124.237 23.2578 130.925 15.0002 130.925ZM15.0002 102.587C7.57597 102.587 1.55324 108.595 1.55324 116C1.55324 123.406 7.57597 129.413 15.0002 129.413C22.4245 129.413 28.4472 123.406 28.4472 116C28.4472 108.595 22.4245 102.587 15.0002 102.587Z" fill="white"/>
<path d="M11.1366 120.61L9.77295 119.93C10.8336 117.776 12.9926 116.454 15.4169 116.454C17.8033 116.454 19.9623 117.776 21.023 119.892L19.6972 120.572C18.8639 118.947 17.2351 117.965 15.4548 117.965C13.5987 117.965 11.9699 118.985 11.1366 120.61Z" fill="#1A1A1A"/>
<path d="M10.379 112.977C11.0066 112.977 11.5154 112.47 11.5154 111.844C11.5154 111.218 11.0066 110.71 10.379 110.71C9.75144 110.71 9.24268 111.218 9.24268 111.844C9.24268 112.47 9.75144 112.977 10.379 112.977Z" fill="black"/>
<path d="M19.091 112.977C19.7185 112.977 20.2273 112.47 20.2273 111.844C20.2273 111.218 19.7185 110.71 19.091 110.71C18.4634 110.71 17.9546 111.218 17.9546 111.844C17.9546 112.47 18.4634 112.977 19.091 112.977Z" fill="black"/>
</g>:""}
{col==10?<g clip-path="url(#clip9_960_2891)">
<path d="M15.0004 0.831299C7.12166 0.831299 0.795898 7.17891 0.795898 15.0001C0.795898 22.8212 7.15953 29.1688 15.0004 29.1688C22.8414 29.1688 29.205 22.8212 29.205 15.0001C29.205 7.17891 22.8414 0.831299 15.0004 0.831299Z" fill="url(#paint11_linear_960_2891)"/>
<path d="M15.0002 29.9246C6.74263 29.9246 0.0380859 23.2369 0.0380859 15.0001C0.0380859 6.76334 6.74263 0.0756836 15.0002 0.0756836C23.2578 0.0756836 29.9623 6.80112 29.9623 15.0001C29.9623 23.1991 23.2199 29.9246 15.0002 29.9246ZM15.0002 1.58702C7.57597 1.58702 1.55324 7.63236 1.55324 15.0001C1.55324 22.3679 7.57597 28.4132 15.0002 28.4132C22.4245 28.4132 28.4472 22.4057 28.4472 15.0001C28.4472 7.59458 22.3866 1.58702 15.0002 1.58702Z" fill="white"/>
<path d="M21.5154 23.3881L20.0002 23.3503L20.0381 21.6879C20.0381 19.0808 17.879 16.9649 15.2654 16.9649C12.6517 16.9649 10.4926 19.1186 10.4926 21.7257V21.839L10.3032 23.3503L8.78809 23.1614L8.97748 21.6879C9.01536 18.2496 11.8184 15.4536 15.2654 15.4536C18.7502 15.4536 21.5532 18.2496 21.5532 21.7257L21.5154 23.3881Z" fill="#1A1A1A"/>
<path d="M10.6442 11.9775C11.2718 11.9775 11.7805 11.47 11.7805 10.844C11.7805 10.2179 11.2718 9.71045 10.6442 9.71045C10.0166 9.71045 9.50781 10.2179 9.50781 10.844C9.50781 11.47 10.0166 11.9775 10.6442 11.9775Z" fill="black"/>
<path d="M19.3185 11.9775C19.9461 11.9775 20.4549 11.47 20.4549 10.844C20.4549 10.2179 19.9461 9.71045 19.3185 9.71045C18.6909 9.71045 18.1821 10.2179 18.1821 10.844C18.1821 11.47 18.6909 11.9775 19.3185 11.9775Z" fill="black"/>
</g>:""}
{col==9?<g clip-path="url(#clip10_960_2891)">
<path d="M15.0004 62.1688C22.8454 62.1688 29.205 55.8253 29.205 48.0001C29.205 40.1749 22.8454 33.8313 15.0004 33.8313C7.15549 33.8313 0.795898 40.1749 0.795898 48.0001C0.795898 55.8253 7.15549 62.1688 15.0004 62.1688Z" fill="url(#paint12_linear_960_2891)"/>
<path d="M15.0002 62.9246C6.74263 62.9246 0.0380859 56.2369 0.0380859 48.0001C0.0380859 39.7633 6.74263 33.0757 15.0002 33.0757C23.2578 33.0757 29.9623 39.7633 29.9623 48.0001C29.9623 56.2369 23.2578 62.9246 15.0002 62.9246ZM15.0002 34.587C7.57597 34.587 1.55324 40.5946 1.55324 48.0001C1.55324 55.4057 7.57597 61.4132 15.0002 61.4132C22.4245 61.4132 28.4472 55.4057 28.4472 48.0001C28.4472 40.5946 22.4245 34.587 15.0002 34.587Z" fill="white"/>
<path d="M21.3258 54.7257H19.8106C19.8106 52.1186 17.6515 49.9649 15.0379 49.9649C12.4242 49.9649 10.2652 52.1186 10.2652 54.7257H8.75C8.75 51.2496 11.553 48.4536 15.0379 48.4536C18.5227 48.4536 21.3258 51.2496 21.3258 54.7257Z" fill="#1A1A1A"/>
<path d="M10.7199 44.9775C11.3475 44.9775 11.8562 44.47 11.8562 43.844C11.8562 43.2179 11.3475 42.7104 10.7199 42.7104C10.0923 42.7104 9.5835 43.2179 9.5835 43.844C9.5835 44.47 10.0923 44.9775 10.7199 44.9775Z" fill="black"/>
<path d="M19.4318 44.9775C20.0594 44.9775 20.5681 44.47 20.5681 43.844C20.5681 43.2179 20.0594 42.7104 19.4318 42.7104C18.8042 42.7104 18.2954 43.2179 18.2954 43.844C18.2954 44.47 18.8042 44.9775 19.4318 44.9775Z" fill="black"/>
</g>:""}
{col==8?<g clip-path="url(#clip11_960_2891)">
<path d="M15.0004 96.1688C22.8454 96.1688 29.205 89.8253 29.205 82.0001C29.205 74.1749 22.8454 67.8313 15.0004 67.8313C7.15549 67.8313 0.795898 74.1749 0.795898 82.0001C0.795898 89.8253 7.15549 96.1688 15.0004 96.1688Z" fill="url(#paint13_linear_960_2891)"/>
<path d="M15.0002 96.9246C6.74263 96.9246 0.0380859 90.2369 0.0380859 82.0001C0.0380859 73.7633 6.74263 67.0757 15.0002 67.0757C23.2578 67.0757 29.9623 73.7633 29.9623 82.0001C29.9623 90.2369 23.2578 96.9246 15.0002 96.9246ZM15.0002 68.587C7.57597 68.587 1.55324 74.5946 1.55324 82.0001C1.55324 89.4057 7.57597 95.4132 15.0002 95.4132C22.4245 95.4132 28.4472 89.4057 28.4472 82.0001C28.4472 74.5946 22.4245 68.587 15.0002 68.587Z" fill="white"/>
<path d="M9.84857 87.4032L8.40918 86.9876C9.16676 84.305 11.6668 82.4536 14.4698 82.4536C17.2349 82.4536 19.7349 84.305 20.4925 86.9876L19.0152 87.4032C18.4092 85.4007 16.5531 83.9649 14.4319 83.9649C12.3107 83.9649 10.4168 85.3629 9.84857 87.4032Z" fill="#1A1A1A"/>
<path d="M10.2653 78.9775C10.8929 78.9775 11.4016 78.47 11.4016 77.844C11.4016 77.2179 10.8929 76.7104 10.2653 76.7104C9.63767 76.7104 9.12891 77.2179 9.12891 77.844C9.12891 78.47 9.63767 78.9775 10.2653 78.9775Z" fill="black"/>
<path d="M18.9772 78.9775C19.6048 78.9775 20.1135 78.47 20.1135 77.844C20.1135 77.2179 19.6048 76.7104 18.9772 76.7104C18.3496 76.7104 17.8408 77.2179 17.8408 77.844C17.8408 78.47 18.3496 78.9775 18.9772 78.9775Z" fill="black"/>
</g>:""}
<defs>
<linearGradient id="paint0_linear_960_2891" x1="16.2036" y1="339.331" x2="16.2036" y2="31.1904" gradientUnits="userSpaceOnUse">
<stop offset="0.00233548" stop-color="#32A600"/>
<stop offset="0.2998" stop-color="#EBFF00"/>
<stop offset="0.6322" stop-color="#FFC255"/>
<stop offset="0.8733" stop-color="#FF0040"/>
<stop offset="0.9603" stop-color="#D71A40"/>
</linearGradient>
<linearGradient id="paint1_linear_960_2891" x1="14.8746" y1="361.791" x2="14.8746" y2="6.2804" gradientUnits="userSpaceOnUse">
<stop offset="0.00233548" stop-color="#32A600"/>
<stop offset="0.2998" stop-color="#EBFF00"/>
<stop offset="0.6322" stop-color="#FFC255"/>
<stop offset="0.8733" stop-color="#FF0040"/>
<stop offset="0.9603" stop-color="#D71A40"/>
</linearGradient>
<linearGradient id="paint2_linear_960_2891" x1="1.88302" y1="355.072" x2="28.4202" y2="355.072" gradientUnits="userSpaceOnUse">
<stop offset="0.000623996" stop-color="#5DC0A0"/>
<stop offset="0.9963" stop-color="#3FA1DA"/>
</linearGradient>
<linearGradient id="paint3_linear_960_2891" x1="0.792073" y1="319.005" x2="29.2012" y2="319.005" gradientUnits="userSpaceOnUse">
<stop stop-color="#71B657"/>
<stop offset="1" stop-color="#00A761"/>
</linearGradient>
<linearGradient id="paint4_linear_960_2891" x1="0.791997" y1="286.005" x2="29.2011" y2="286.005" gradientUnits="userSpaceOnUse">
<stop stop-color="#B2CB4E"/>
<stop offset="1" stop-color="#71B657"/>
</linearGradient>
<linearGradient id="paint5_linear_960_2891" x1="0.792073" y1="252.005" x2="29.2012" y2="252.005" gradientUnits="userSpaceOnUse">
<stop stop-color="#E8DF3F"/>
<stop offset="1" stop-color="#B2CB4E"/>
</linearGradient>
<linearGradient id="paint6_linear_960_2891" x1="0.791997" y1="218.005" x2="29.2011" y2="218.005" gradientUnits="userSpaceOnUse">
<stop offset="0.000330688" stop-color="#FFE73A"/>
<stop offset="1" stop-color="#E8DF3F"/>
</linearGradient>
<linearGradient id="paint7_linear_960_2891" x1="0.791997" y1="150.005" x2="29.2011" y2="150.005" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFC63B"/>
<stop offset="1" stop-color="#FAC23C"/>
</linearGradient>
<linearGradient id="paint8_linear_960_2891" x1="0.791333" y1="184.005" x2="29.2004" y2="184.005" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFC63B"/>
<stop offset="0.9997" stop-color="#FFE73A"/>
</linearGradient>
<linearGradient id="paint9_linear_960_2891" x1="0.791333" y1="184.005" x2="29.2004" y2="184.005" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFC63B"/>
<stop offset="0.9997" stop-color="#FFE73A"/>
</linearGradient>
<linearGradient id="paint10_linear_960_2891" x1="0.792073" y1="116.005" x2="29.2012" y2="116.005" gradientUnits="userSpaceOnUse">
<stop offset="0.00183832" stop-color="#EF7C47"/>
<stop offset="1" stop-color="#FFC63B"/>
</linearGradient>
<linearGradient id="paint11_linear_960_2891" x1="0.778588" y1="15.0127" x2="29.1877" y2="15.0127" gradientUnits="userSpaceOnUse">
<stop stop-color="#902348"/>
<stop offset="1" stop-color="#E72144"/>
</linearGradient>
<linearGradient id="paint12_linear_960_2891" x1="0.792073" y1="48.0049" x2="29.2012" y2="48.0049" gradientUnits="userSpaceOnUse">
<stop offset="0.00387549" stop-color="#E81776"/>
<stop offset="0.9992" stop-color="#F1872D"/>
</linearGradient>
<linearGradient id="paint13_linear_960_2891" x1="0.791997" y1="82.0049" x2="29.2011" y2="82.0049" gradientUnits="userSpaceOnUse">
<stop offset="0.000721765" stop-color="#EE612B"/>
<stop offset="1" stop-color="#F08131"/>
</linearGradient>
<clipPath id="clip0_960_2891">
<rect width="356" height="14" fill="white" transform="matrix(0 1 -1 0 22.0015 6.00098)"/>
</clipPath>
<clipPath id="clip1_960_2891">
<rect width="30" height="30" fill="white" transform="translate(0 340)"/>
</clipPath>
<clipPath id="clip2_960_2891">
<rect width="30" height="30" fill="white" transform="translate(0 304)"/>
</clipPath>
<clipPath id="clip3_960_2891">
<rect width="30" height="30" fill="white" transform="translate(0 271)"/>
</clipPath>
<clipPath id="clip4_960_2891">
<rect width="30" height="30" fill="white" transform="translate(0 237)"/>
</clipPath>
<clipPath id="clip5_960_2891">
<rect width="30" height="30" fill="white" transform="translate(0 203)"/>
</clipPath>
<clipPath id="clip6_960_2891">
<rect width="30" height="30" fill="white" transform="translate(0 135)"/>
</clipPath>
<clipPath id="clip7_960_2891">
<rect width="30" height="30" fill="white" transform="translate(0 169)"/>
</clipPath>
<clipPath id="clip8_960_2891">
<rect width="30" height="30" fill="white" transform="translate(0 101)"/>
</clipPath>
<clipPath id="clip9_960_2891">
<rect width="30" height="30" fill="white"/>
</clipPath>
<clipPath id="clip10_960_2891">
<rect width="30" height="30" fill="white" transform="translate(0 33)"/>
</clipPath>
<clipPath id="clip11_960_2891">
<rect width="30px" height="30" fill="white" transform="translate(0 67)"/>
</clipPath>
</defs>
</svg>





    // </div>
  )
}

export default ScaleV