import {React,useState} from 'react'
import sun from '../../Images/Dashboard/toggle_sun.svg'
import moon from '../../Images/Dashboard/toggle_moon.svg'
import Gauge_Chart from './Gauge_Chart'
import infosvg from "../../Images/Dashboard/info.svg"
import { Link } from 'react-router-dom'
// import { useQuery } from "react-query";
// import axios from 'axios'
// import useAuth from '../../Hook/useAuth';
// import  {useHistory , useLocation }  from "react-router-dom";

const Gauge = () => {
    const [tab ,setTab] = useState(1)
    // const [datachart ,setDatachart] = useState([{}])
    const [days ,setDays] = useState("weekly")
    const [daystate ,setDaystate] = useState("Evening")
//     const { auth , logout , setAuth } = useAuth()
//     const navigate = useHistory()
    
    
//     const { isLoading, isError, data, error, refetch } = useQuery(["measure"], () =>
//     axios.get(`function_patient/exercise/measure/${days}` ,
//     { 
//     headers: { 'Content-Type': 'application/json'}
//     } ,
//     {'Access-Control-Allow-Credentials': true}) ,
  
//     {
//         onSuccess: (res) => {
//           const result = {
//             data: res.data,
//           };  
//           setDatachart(result.data)
//         },
//         onError: (err) => {
//         },
//     }
//     )
  
//   if (error) 
//   {
//     if(!auth)
//     {
//       navigate.replace('/Login')
//     }
//     return "An error has occurred: " + error.message;
//   } 
//    console.log(data?.data)


    
  return (
    <div className='flex flex-col p-2 bg-white rounded-2xl'>
    
    <text   style={{fontSize:"5vw"}} className='font-Hebbo text-center font-medium'>נתונים לשבוע האחרון</text>
    <div className='flex flex-row-reverse justify-between py-[2px]  rounded-lg'>
        <button className={tab==1 ?"bg-[#EFEEEE] font-medium rounded-md p-2 px-3":"text-gray-400 p-2 px-3 rounded-md bg-[#F1ECEE]"} onClick={()=>setTab(1)}>תשישות</button>
        <button className={tab==2 ?"bg-[#EFEEEE] font-medium rounded-md p-2 px-3":"text-gray-400 p-2 px-3 rounded-md bg-[#F1ECEE]"} onClick={()=>setTab(2)}>דחק</button>
        <button className={tab==3 ?"bg-[#EFEEEE] font-medium rounded-md p-2 px-3":"text-gray-400 p-2 px-3 rounded-md bg-[#F1ECEE]"} onClick={()=>setTab(3)}>כאב</button>
        <button className={tab==4 ?"bg-[#EFEEEE] font-medium rounded-md p-2 px-3":"text-gray-400 p-2 px-3 rounded-md bg-[#F1ECEE]"} onClick={()=>setTab(4)}>רווחה נפשית</button>

    </div>

    <div className='flex flex-row-reverse w-full justify-around mt-2 p-4 py-2 bg-[#EFEEEE] rounded-lg'>
        <button className={days=="weekly" ?"":"text-gray-400 "} onClick={()=>setDays("weekly")}>שבוע</button>
        <button className={days=="monthly" ?"":"text-gray-400"} onClick={()=>setDays("monthly")}>חודש</button>
        <button className={days=="all" ?"":"text-gray-400"} onClick={()=>setDays("all")}>הכל</button>

    </div>



    <div className='flex flex-row-reverse justify-between p-3 pr-1'>
        <div className='flex flex-row'>
            <img src={moon} alt="moon" className="w-[10vw] px-2"/>
            <label class="relative inline-flex items-center cursor-pointer">
            {/* <input type="checkbox" value="" class="sr-only peer"/>
            <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4  rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-gradient-to-r from-[#E12346] to-[#765599]  after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all "></div> */}
            <div className='flex w-[13vw]   bg-[#EFEEEE]  rounded-3xl' onClick={()=>{ 
             {daystate =="Evening"? setDaystate("Morning"): setDaystate("Evening") }
                // refetch()
                }}>

                <span className={`h-[6vw] w-[6vw] m-[1px] ${daystate=="Morning"?"ml-[7vw]":""} bg bg-gradient-to-r from-[#E12346] to-[#765599] rounded-full transition-all duration-300`}></span>
            </div>
</label>
            <img src={sun} alt="sun" className="w-[10vw] px-2"/>
        </div>
        <div className='flex'> 
        
        <button>  <Link to="/About"><img src={infosvg} alt="infosvg" className="w-[6vw]"/></Link></button>
        <p className='p-2'> שינוי </p>
        </div>

 
    </div>
    
    
        {days=="weekly"?<Gauge_Chart Tub={tab} days={"weekly"}  daystate={daystate}></Gauge_Chart>:""}
        {days=="monthly"?<Gauge_Chart Tub={tab} days={"monthly"}  daystate={daystate}></Gauge_Chart>:""}
        {days=="all"?<Gauge_Chart Tub={tab} days={"all"}  daystate={daystate}></Gauge_Chart>:""}
        
    <div className='w-full px-4'>
            <div className=' flex flex-row-reverse items-center' >
                <div className='bg-[#009ED1] mx-3 rounded-full w-[3vw] h-[3vw]'></div>
                <div className='' style={{fontSize:"1rem"}}>שיפור משמעותי מאוד</div>
            </div>

            <div className=' flex flex-row-reverse items-center' >
                <div className='bg-[#69B25D] mx-3 rounded-full w-[3vw] h-[3vw]'></div>
                <div>שיפור משמעותי </div>
            </div>
            <div className=' flex flex-row-reverse items-center' >
                <div className='bg-[#EBDA51]  mx-3 rounded-full  w-[3vw] h-[3vw]'></div>
                <div>שיפור קל</div>
            </div>
            <div className=' flex flex-row-reverse items-center' >
                <div className='bg-amber-400 mx-3 rounded-full w-[3vw] h-[3vw]'></div>
                <div>אין שיפור</div>
            </div>
            <div className=' flex flex-row-reverse items-center' >
                <div className='bg-[#FA6B38] mx-3 rounded-full w-[3vw] h-[3vw]'></div>
                <div>ירידה</div>
            </div>
        
    </div>
    </div>
  )
}

export default Gauge