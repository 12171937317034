import React from 'react'
import Arrow_grey_rigth from "../Images/Arrows/arrow_Grey_right.svg"
import Stage1_lock from "../Images/Stages_lock/Stage1_lock.svg"
import Stage2_lock from "../Images/Stages_lock/Stage2_lock.svg"
import Stage3_lock from "../Images/Stages_lock/Stage3_lock.svg"
import Stage4_lock from "../Images/Stages_lock/Stage4_lock.svg"
import Stage5_lock from "../Images/Stages_lock/Stage5_lock.svg"

const Stage_Luck = ({item,index}) => {
const arr={1:Stage2_lock,
  2:Stage3_lock,
  3:Stage4_lock,4:Stage5_lock}
  return (
           
    <div className='bg-white  rounded-2xl flex flex-row justify-between items-center border border-b-4 m-2 p-3 py-7'>
      {/* <img className='w-[10px] mx-4' src={item.arrow} alt="sda" onClick={()=>{SetHide(!hide)}}/>   */}
      <img className='w-[10px] mx-4' src={Arrow_grey_rigth} alt="sda" />  
    <p className='text-luck'>{item.nameOfLevel}</p>
    <img className='w-[50px] mr-2' src={arr[index]} alt="sda"/>
    
    </div>
    
  )
}

export default Stage_Luck