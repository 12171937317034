import React from 'react'
import Video from './Video'
//כאן צריך לשנות אם עוז ירצה
const Trainings_List = (props) => {
  {console.log(props)}
  return (
    <div className='my-2 mr-2 p-2 bg-white rounded-3xl'>
    <div id="main-title" className='flex justify-end font-normal font-Hebbo text-2xl p-4 text-black text-right '>
        {props.item.main_title}
    </div >
    <div className='flex flex-row-reverse overflow-x-auto'  bg-midnight="true">
            {/* <Video Video={props.Video} time="1:30" discrption="נשימה עם דיבור עצמי חיובי"/> */}
            {props.item.info_videos.map((video)=>( <Video time={video.total_train_time} actionId={video.actionId} discrption={video.name} levelId={props.levelId} urlImage={video.urlImage}/>))}
           
            {/* <Video time="1:30"  discrption="התמודדות עם פחדים או רגשות שליליים עם מוסיקה"/>
            <Video time="1:30"  discrption="sdadcc"/>
            <Video time="1:30"  discrption="sdadcc"/>
            <Video time="1:30"  discrption="sdadcc"/>
            <Video time="1:30"  discrption="sdadcc"/> */}
    </div>
    </div>
  )
}

export default Trainings_List