// import React from 'react'
// import Video1 from "../Images/Videos/stage1_videoCover_list.svg"
import Video1 from "../Images/Videos/stage1_videoCover.svg"
import Back from "../Images/Keys/back.svg"
import Fwd from "../Images/Keys/fwd.svg"
import Pause from "../Images/Keys/pause.svg"
import Play from "../Images/Keys/Play.svg"
import Stop from "../Images/Keys/stop.svg"
import Header from './Header';
import Nav from './Nav';
import axios from 'axios'
// import ReactPlayer from "react-player";
import {React ,StrictMode, useEffect, useRef, useState}from 'react'
import { click } from "@testing-library/user-event/dist/click"
// import reactPlayer from 'react-player'



const VideoBig = ({actionIdBuild , type ,  setCurrentPage ,videoUrl ,urlImage ,levelId ,execiserIdBuild,description}) => {


  const onSubmit = (e) =>
  {
    e.preventDefault()
    if(type === "Guild")
    {
      //api that we finish a guild: 
      onFinishGuild()
      // // localStorage.removeItem("urlImage")
      // //   localStorage.removeItem("urlVideo")
      // //   localStorage.removeItem("description")
      localStorage.removeItem("TimeVideo")
      localStorage.removeItem("success")
      // localStorage.removeItem("execiserId")
      setCurrentPage('Finish')
    }
    else{
      // localStorage.removeItem("urlImage")
      //   localStorage.removeItem("urlVideo")
      //   localStorage.removeItem("description")
      localStorage.removeItem("TimeVideo")
      localStorage.removeItem("success")
      localStorage.removeItem("execiserId")
      localStorage.setItem('position' ,"q2")

      setCurrentPage('q2')
    }
  }

  const onFinishGuild = async() =>
  {
      await axios.post('function_patient/endingActionOfGuild' , 
      JSON.stringify({actionId: actionIdBuild}) , 
      { 
        headers: { 'Content-Type': 'application/json'}
      } ,
      {'Access-Control-Allow-Credentials': true})
        .then((res) => {
        if(res?.status === 200)
         {
            console.log(res?.data)
         }
         else{
            //logoutFun()
            console.log(res?.response?.data)
         }
      })
        .catch( (err) => 
            {
              // console.log(err.response?.status)
              // if(err.response?.status === 401)
              // {
              //   logoutFun()
              // }
            })

  }
  const [counter_open,setCounter_Open]=useState(0)
  const [open,setOpen]=useState(localStorage.getItem('success')=="false"?false:true)
  const clickRef=useRef();
  const videoRef=useRef();
  // const [currentTime,setCurrentTime]=useState(localStorage.getItem('position')?localStorage.getItem('position'):0)
  const [currentTime,setCurrentTime]=useState(localStorage.getItem('TimeVideo')?localStorage.getItem('TimeVideo'):0)
  // const [currentTime,setCurrentTime]=useState(70)
  const [duration,setDuration]=useState(0)
  const [sector,setsector]=useState(0)
  const [hide,sethide]=useState(true)



  const playVideo= ()=>{
    videoRef.current.play()

    setsector(3)
    sethide(false)

  }
  const pauseVideo= ()=>{
    videoRef.current.pause()
    setsector(5)
    sethide(true)
  }
  const stopVideo= ()=>{
    videoRef.current.pause()
    setsector(2)
    sethide(true)
  }
  const fastForward= ()=>{
    videoRef.current.currentTime+=10;
    setsector(4)
  }

  const rewind= ()=>{
    videoRef.current.currentTime-=10;
    setsector(1)

  }
  useEffect(()=>{
      setTimeout(()=>setDuration(videoRef?.current?.duration),100)
  

  
   
  },[videoRef?.current?.duration])


  useEffect(()=>{
    
    //  const video =videoRef.current
    const cTime =videoRef?.current?.currentTime
    // counter_open >=10&& (duration <= (cTime +100))? setOpen(true): setTimeout(() => setCounter_Open(counter_open + 1), 1000);
    // counter_open&& (duration <= (cTime +100))? setOpen(true): ;
    if (counter_open>3 &&!open && duration <= (cTime +100))
      {setOpen(true)
        localStorage.setItem('success' ,true)
      }
    setTimeout(() => setCounter_Open(counter_open + 1), 1000)
    if(counter_open%5==0)
      { localStorage.setItem('TimeVideo' ,cTime)}
    console.log (cTime,counter_open,duration)
    // const loadedMetadataHandler=()=>{
    // setDuration(videoRef.current.currentTime  )
    // }

    
  },[counter_open ])



  useEffect(()=>{
    videoRef.current.currentTime=currentTime;
  },[])

  const timeUpdateHandler=()=>{
    // setCurrentTime(video.currentTime)
        const duration1=videoRef.current.duration
  const cTime =videoRef.current.currentTime
  
  setCurrentTime(cTime / duration1 * 100)
  }
  const checkWidth =(e)=>{
  let width = clickRef.current.clientWidth;
  const offset = e.nativeEvent.offsetX
  const divWidth = offset / width * 100 ;
  videoRef.current.currentTime = (divWidth / 100 * videoRef.current.duration )
}
const toHoursAndMinutes=(totalSeconds)=> {

  if(!isNaN(totalSeconds) )
  {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = Math.floor(totalSeconds % 60,2);
    // const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    // console.log(`${hours}:${minutes}:${seconds}`)
    // return (`${hours>10?hours:"0"+hours}:${minutes>10?minutes:"0"+minutes}:${seconds>10?seconds:"0"+seconds}`);
    return (`${totalMinutes>10?totalMinutes:"0"+totalMinutes}:${seconds>10?seconds:"0"+seconds}`);
  }
  



//קוד בשביל הקרון:
  // var text ="Edit and save to reload. Edit and save to reload. Edit save to reload. Edit save to reload. Edit save to reload. Edit save to reload. Edit save to reload. Edit save to reload." 
 

   {/* <div>

        
        <p className={`w-[250px]  ${spamshow&&show?"break-words line-clamp-2":""} `}>
          {text}
          
        </p>
        {spamshow&&<button className='bg-bubble-gum' onClick={()=>{setshow(!show)}}>{show?"המשך לקרוא":"סגור"}</button>}
        </div> */}



}
const spamshow= description.length>80? true : false
const [show,setshow]=useState(false)

  return (
  
    <div className='min-h-full flex flex-col w-[100vw] p-3 ' style={{background: "linear-gradient(0deg, #F0F0F0 0.06%, #F4F4F4 82.66%, #F6F6F6 100%)"}}>
       
       
        
           <div className="container bg-no-repeat bg-contain rounded-xl" style={{backgroundImage:`url("${urlImage}")`}}>
           {/* <img src={Video1} alt="asd" className=' absolute w-full rounded-xl opacity-100' /> */}
       
        {(hide)?
        <img src={Video1} alt="asd" className=' w-full rounded-xl opacity-100' />:<img src={Video1} alt="asd" className=' w-full rounded-xl opacity-0' />}
        
        </div>
           {/* <div className="container relative min-h-[50%] ">
           <img src={Video1} alt="asd" className=' absolute w-full rounded-xl opacity-100' />
       
        <img src={urlImage} alt="asd" className=' w-full rounded-xl opacity-100' />
        
        </div> */}
        
        
        {/* <img className="opacity-10" src={Video1} alt="" /> */}
        <audio  width="320" height="240" preload="metadata"  src={videoUrl} type={"audio/mp3"} ref={videoRef} onTimeUpdate={timeUpdateHandler}></audio>

        {/* <video playsInline   className="w-full rounded-xl"  height="240"  ref={videoRef} poster={urlImage}  webkit-playsInline={true} >
          
          <source src={videoUrl} type={"audio/mp3"}   /> */}
          {/* <source src={"https://female-video.s3.amazonaws.com/tagmp3_Invitation-to-a-safe-place-with-audio+(1).mp3"} type={"audio/mp3"}  /> */}
          
      {/* </video> */}

     

          <div className="w-[95%] flex self-center h-[5px]  rounded-3xl mt-2 bg-slate-400" onClick={checkWidth} ref={clickRef}>
            <div className={` h-full rounded-3xl bg-green-500`} style={{width:`${currentTime+"%"}`}}></div>
          </div>
          <p>{toHoursAndMinutes(videoRef?.current?.currentTime)}/{toHoursAndMinutes(duration)}</p>


        <nav className='flex flex-row justify-between  border-b-2 w-full' > 
            <button  className={sector==1?' bg-white rounded-xl' :""} onClick={rewind}><img src={Back} alt="asd" className='w-full'/></button>
            <button className={sector==2?' bg-white rounded-xl':""} onClick={stopVideo}><img src={Stop} alt=""className='w-full' /></button>
            <div className={sector==3?' bg-white rounded-xl' :""} onClick={playVideo}><img src={Play} alt="" className='w-full'/></div>
            <div className={sector==4?' bg-white rounded-xl' :""} onClick={fastForward}><img src={Fwd} alt="asd" className='w-full'/></div>
            <div onClick={pauseVideo} className={sector==5?' bg-white rounded-xl':""}><img src={Pause} alt="" className='w-full'/></div>
                
        
        </nav>

        <p className= {`whitespace-pre-line text-right font-Hebbo font-normal text-lg px-3 ${spamshow&&!show?"line-clamp-3" : ""} `} style={{direction: 'rtl'}}>
                
                {description}
                
        </p>
        {spamshow&&<button className='mx-2 p-2 rounded-md bg-red-100' onClick={()=>{setshow(!show)}}>{!show?"המשך לקרוא":"סגור"}</button>}

        <div className='w-full flex justify-between px-4 mt-3 flex-row '>
            {open?<button className='rounded-2xl  p-4  font-Hebbo font-normal text-white flex justify-end 'style={{background:"#394351"}} onClick={onSubmit}>המשך</button>
            :<button className=' rounded-lg  p-4 text-white bg-gray-300  focus:outline-none' disabled >המשך</button>}
        </div>
    </div>
  )
}

export default VideoBig