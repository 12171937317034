import React from 'react'
import location from "../Images/Home/Location.png"


import icon_skill1 from "../Images/Home/Home_bubble_up/icon_skills/icon_skill1.svg"
import icon_skill2 from "../Images/Home/Home_bubble_up/icon_skills/icon_skill2.svg"
import icon_skill3 from "../Images/Home/Home_bubble_up/icon_skills/icon_skill3.svg"
import icon_skill4 from "../Images/Home/Home_bubble_up/icon_skills/icon_skill4.svg"
import icon_skill5 from "../Images/Home/Home_bubble_up/icon_skills/icon_skill5.svg"


import icon_practice1 from "../Images/Home/Home_bubble_up/Practice/icon_practice1.svg"
import icon_practice2 from "../Images/Home/Home_bubble_up/Practice/icon_practice2.svg"
import icon_practice3 from "../Images/Home/Home_bubble_up/Practice/icon_practice3.svg"
import icon_practice4 from "../Images/Home/Home_bubble_up/Practice/icon_practice4.svg"
import icon_practice5 from "../Images/Home/Home_bubble_up/Practice/icon_practice5.svg"

import icon_days1 from "../Images/Home/Home_bubble_up/Days/icon_days1.svg"
import icon_days2 from "../Images/Home/Home_bubble_up/Days/icon_days2.svg"
import icon_days3 from "../Images/Home/Home_bubble_up/Days/icon_days3.svg"
import icon_days4 from "../Images/Home/Home_bubble_up/Days/icon_days4.svg"
import icon_days5 from "../Images/Home/Home_bubble_up/Days/icon_days5.svg"


// import {ReactComponent as Icon_skill} from "../Images/Home/Home_bubble_up/icon_skills/icon_skill1.svg"

const Home_bubble_up = ({attributeMyLevel,attributeCompleteLevel}) => {
  const arrcolor=
    {1:"linear-gradient(rgba(167, 123, 171, 1), rgba(148, 113, 166, 1), rgba(115, 95, 158, 1), rgba(101, 88, 155, 1))",
    2:"linear-gradient(3deg, #0C0C0D 0%, #EB812B 0.08%, #4990CA 0.53%, #685197 99.59%)",
    3:"linear-gradient(3deg, #5DB696 0.11%, #4097D1 99.63%)",
    4:"linear-gradient(3deg, #EB812B 0.08%, #E11876 99.61%)",
    5:"linear-gradient(3deg, #FFC53D 0%, #EC7C47 99.82%)"}

    const icon_skills=
    {1:icon_skill1,
    2:icon_skill2,
    3:icon_skill3,
    4:icon_skill4,
    5:icon_skill5}

    const practices=
    {1:icon_practice1,
    2:icon_practice2,
    3:icon_practice3,
    4:icon_practice4,
    5:icon_practice5}

    const icon_days=
    {1:icon_days1,
    2:icon_days2,
    3:icon_days3,
    4:icon_days4,
    5:icon_days5}

    var index=attributeMyLevel?.levelId
  
    return (
    <div className=' bg-white rounded-2xl flex flex-col p-2 m-2'>
    <div className='w-full flex flex-row-reverse rounded-t-2xl p-2' style={{background:arrcolor[attributeMyLevel?.levelId]}}>
        {/* <Icon_skill style={{fill: "red"}}/> */}
        
        <div className='w-[7%] flex justify-center items-center'>
        <img  className="" src={location} alt='image_destination'/>
        </div>
        <div className=''>
        <p className='font-Hebbo font-light text-white text-right pr-1 pl-2' style={{fontSize:"5vw"}}>:הינך בשלב {attributeMyLevel?.levelId}</p>
        </div>
        <div className=''>
        <p className='font-Hebbo font-medium text-white text-right' style={{fontSize:"5vw"}}>{attributeMyLevel?.nameLevel} </p>
        </div>
    </div>
   
   
   
    <div className='flex flex-row text-right border-b-2 border-b-ma my-2'>
        <div className='w-[30%] flex flex-col text-right '>
                
                <div className='h-[3vh] flex justify-end pr-3 '>
                <img   className="w-[5vw] " src={practices[attributeMyLevel?.levelId]} alt='image_destination'/>
                </div>
               
                <div className=' flex flex-col border-r-2 border-ma pr-3'>
                <p className='font-Hebbo font-bold text-black text-right mb-[-8px]' style={{fontSize:"7vw"}}>{attributeMyLevel?.number_action_min}</p>
                <p className='font-Hebbo font-normal text-black text-right' style={{fontSize:"5vw"}}>תרגולים</p>
               
                </div>
        </div>

        <div className='w-[50%]' >
            <div className='h-[3vh] flex justify-end pr-3'>
            <img   className="w-[4vw] " src={icon_skills[index]} alt='image_destination'/>
                </div>
               
                <div className=' flex flex-col border-r-2 border-r-ma pr-3'>
                <p className='font-Hebbo font-bold text-black text-right mb-[-8px]' style={{fontSize:"7vw"}}>{attributeMyLevel?.number_tech}</p>
                <p className='font-Hebbo font-normal text-black text-right' style={{fontSize:"5vw"}}>מיומנויות</p>
                </div>
        </div>

        <div className='w-[20%]'>
        <div className='h-[3vh] flex justify-end pr-3'>
        <img   className="w-[4vw] " src={icon_days[index]} alt='image_destination'/>
                </div>
               
                <div className=' flex flex-col pr-3'>
                <p className='font-Hebbo font-bold text-black text-right mb-[-8px]' style={{fontSize:"7vw"}}>{attributeMyLevel?.days_level}</p>
                <p className='font-Hebbo font-normal text-black text-right' style={{fontSize:"5vw"}}>ימים</p>
                </div>
        </div>


    </div>
    <div className='w-full  px-4 flex flex-row-reverse rounded-t-2xl ' style={{background:arrcolor[attributeMyLevel?.levelId]}}>
    <p className='font-Hebbo font-medium text-white text-right' style={{fontSize:"5vw"}}>:להשלמת השלב נותרו לך</p>
    </div>

    <div className=' flex flex-row mt-4'>
        <div className='w-[85%] divide-y-2 divide-ma border-r-2 border-ma pl-2 '>
            
        {attributeCompleteLevel?.needExerciseCompletes?.map((row)=>{return(
            <div className=' flex flex-row'>
             <p className='w-[20%] font-Hebbo font-light text-black text-right pr-1' style={{fontSize:"4vw"}}>  תרגולים</p>
             <p className='w-[10%] font-Hebbo font-light text-black text-right pr-4' style={{fontSize:"4vw"}}> {row?.needExerciseComplete} </p>
             <p className='w-[70%] font-Hebbo font-light text-black text-right pr-2' style={{fontSize:"4vw"}}>{row?.nameTech}</p>
             </div>
    )})}

            
            {/* <p className='font-Hebbo font-bold text-black text-right pr-2' style={{fontSize:"5vw"}}>15</p>
            <p className=' font-Hebbo font-bold text-black text-right pr-2' style={{fontSize:"5vw"}}>15</p>
            <p className=' font-Hebbo font-bold text-black text-right pr-2' style={{fontSize:"5vw"}}>15</p> */}
            {/* <p className=' font-Hebbo font-bold text-black text-right pr-2' style={{fontSize:"5vw"}}>15</p> */}
        </div>
        <div className='w-[15%] flex justify-center items-center flex-col'>
            <p className=' font-Hebbo font-bold text-black text-right' style={{fontSize:"8vw"}}>{attributeCompleteLevel?.needDaysComplete}</p>
            <p className='font-Hebbo font-normal text-black text-right' style={{fontSize:"5vw"}}>ימים</p>
        </div>
    </div>
    </div>
  )
}

export default Home_bubble_up