import React from 'react'
import Stage from './Stage'
import Stage_Luck from './Stage_Luck'




import Arrow_grey_rigth from "../Images/Arrows/arrow_Grey_right.svg"
import Arrow_Whit_right from "../Images/Arrows/arrow_Whit-right.svg"
import { useEffect } from 'react'
const Body_home = ({stages}) => {
  // const ind=myPosition;
  // useEffect ( ( ) => 
  //   { 
  //       console.log(mydata)
  //   } , [mydata])
  // const stages=[
  //   {
  //       id:Stage1,
  //       colorstage:"",
  //       // title:"מיומנות התנהגותית",
  //       title:mydata.infoLevel?.levelMap[1]?.levelName,
  //       subTitle:[{status:"",name:""},{status:"",name:""},{status:"",name:""}]
  //       // arrow:ind<1?Arrow_grey_rigth:Arrow_Whit_right
  //   },
  //   {
  //       id:Stage2_lock,
  //       colorstage:"",
  //       title:mydata.infoLevel?.levelMap[2]?.levelName,
  //       // title:"דמיון מודרך",
  //       // subTitle:[{status:"",name:""},{status:"",name:""},{status:"",name:""}]
        
  //       // arrow:Arrow_grey_rigth
  //   },
  //   {
  //       id:Stage3_lock,
  //       colorstage:"",
  //       title:mydata.infoLevel?.levelMap[3]?.levelName,
  //       // subTitle:[{status:"",name:""},{status:"",name:""},{status:"",name:""}]
        
  //   }
  //   ,
  //   {
  //       id:Stage4_lock,
  //       colorstage:"",
  //       title:"מדיטציות מיינדפולנס",
  //       // subTitle:[{status:"",name:""},{status:"",name:""},{status:"",name:""}]
        
  //   }
  //   ,
  //   {
  //       id:Stage5_lock,
  //       colorstage:"",
  //       title:"איך ממשיכים מכאן",
  //       // subTitle:[{status:"",name:""},{status:"",name:""},{status:"",name:""}]
        
  //   }
    
    
  // ];
  
    return (
    <div>
      
       {/* { stages.map( (stage,index) => */}
       { stages?.map( (stage,index) =>
       {
        return(
            

            (stage.status=="Lock")? <Stage_Luck item={stage} index={index}/>:
            <Stage item={stage} index={index}/> 
        )

       })
       }

    </div>
  )
}

export default Body_home