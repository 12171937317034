import {React,  useEffect,  useState } from 'react'
import smile_normal from  "../../../Images/Smiles/5.svg"
import smile_positive from  "../../../Images/Smiles/2.svg"
import smile_negative from  "../../../Images/Smiles/8.svg"

const Negative_feedback = ({textRule,deltawell_being ,deltafatigue ,deltavas ,deltasuds} ) => {
  const [status,SetStaus]=useState([])
  useEffect(()=>{
    ((deltawell_being>0)||(deltafatigue>0)||(deltavas>0)||(deltasuds>0))?
    SetStaus(["linear-gradient(90deg,rgba(220, 226, 127, 0.23), rgba(211, 221, 123, 0.23), rgba(186, 208, 112, 0.23), rgba(147, 190, 94, 0.23), rgba(89, 168, 76, 0.23), rgba(51, 160, 72, 0.23))",smile_positive]):
    ((deltawell_being<0)||(deltafatigue<0)||(deltavas<0)||(deltasuds<0))?
    SetStaus(["linear-gradient(90deg,rgba(238, 128, 47, 0.3), rgba(235, 98, 44, 1))",smile_negative]):
    SetStaus(["linear-gradient(90deg,rgba(230, 222, 62, 0.4), rgba(238, 214, 62, 0.54), rgba(249, 201, 61, 0.83), rgba(255, 197, 61, 1))",smile_normal])
  },[])

  console.log(deltawell_being ,deltafatigue ,deltavas ,deltasuds)
  // const status=" "
  // {((deltawell_being<0)||(deltafatigue<0)||(deltavas<0)||(deltasuds<0))?
  //   status="linear-gradient(90deg,rgba(238, 128, 47, 0.3), rgba(235, 98, 44, 1))" : ((deltawell_being>0)||(deltafatigue>0)||(deltavas>0)||(deltasuds>0))?
  //   status="linear-gradient(90deg,rgba(238, 128, 47, 0.3), rgba(235, 98, 44, 1))" 
  //   : status="linear-gradient(90deg,rgba(238, 128, 47, 0.3), rgba(235, 98, 44, 1))"
  // }
  return (
    <div className="w-full flex flex-row p-2 rounded-t-2xl"style={{background:status[0]}}>
        <p className='w-[90%] px-2  font-Hebbo text-right font-medium whitespace-pre-line ' style={{fontSize:"6vw", direction: "rtl"}}> {textRule}
</p>
        <img className='w-[10%]' src={status[1]} alt="img smile_negative" />
        
    </div>
  )
}

export default Negative_feedback