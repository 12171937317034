import {React, useState} from 'react'
import { useQuery } from "react-query";
import axios from 'axios'
import useAuth from '../../Hook/useAuth';
import  {useHistory , useLocation }  from "react-router-dom";
import Dashbord_Tutorilias_info_chart from './Dashbord_Tutorilias_info_chart';

const Dashbord_Tutorilias = () => {

    const [tab ,setTab] = useState("weekly")


    const { auth , logout , setAuth } = useAuth()
    const navigate = useHistory()
    
    // const[infoTime,setinfoTime]=useState("weekly")

    const { isLoading, isError, data, error, refetch } = useQuery([`exercise/initPeriod/${tab}`], () =>
    axios.get(`function_patient/exercise/initPeriod/${tab}` ,
    { 
    headers: { 'Content-Type': 'application/json'}
    } ,
    {'Access-Control-Allow-Credentials': true}) )
  
  
  if (error) 
  {
    if(!auth)
    {
      navigate.replace('/Login')
    }
    return "An error has occurred: " + error.message;
  } 
   console.log(data?.data)

  return (
    <div className='flex flex-col p-2 items-end bg-white rounded-2xl rounded-tr-none'>
    
    <text   style={{fontSize:"5vw"}} className='font-Hebbo text-center font-medium border-b-2 border-rose-500 w-full'>{tab =="weekly"?" נתונים לשבוע האחרון":tab =="monthly"?" נתונים ל-4 השבועות האחרונים":"נותונים לכל התקופה "}</text>
    
    <div className='flex flex-row-reverse justify-between w-[90%] pr-4'>
        <div className='flex flex-col w-[20%] '>
            <p className='flex items-end'><p className=' font-bold ' style={{fontSize:"1.5rem"}}>{data?.data?.days_Work}</p>/{tab =="weekly"?"7":tab =="monthly"?"28":"100"}</p>
            <p>ימים</p>
        </div>

        <div className='flex flex-col items-end w-[20%]'>
            <p className='flex items-end'><p className=' font-bold ' style={{fontSize:"1.5rem"}}>{data?.data?.amountSession}</p>/{tab =="weekly"?"14":tab =="monthly"?"56":"200"}</p>
            <p>תרגולים</p>
        </div>

        <div className='flex flex-col items-end w-[40%]'>
        <p className=' flex font-bold ' style={{fontSize:"1.5rem"}}> <p className= 'flex font-normal items-end' style={{fontSize:"1rem"}}>דקות</p>{data?.data?.averageMinusPerDay}</p>
            <p>  ממוצע יומי</p>
        </div>
    </div>
    
    
    <div className='flex flex-row-reverse w-full justify-around mt-2 p-4 py-2 bg-[#EFEEEE] rounded-lg'>
        <button className={tab=="weekly" ?"":"text-gray-400"} onClick={()=>setTab("weekly")}>שבוע</button>
        <button className={tab=="monthly" ?"":"text-gray-400"} onClick={()=>setTab("monthly")}>חודש</button>
        <button className={tab=="all" ?"":"text-gray-400"} onClick={()=>setTab("all")}>הכל</button>

    </div>




        
        <Dashbord_Tutorilias_info_chart tab={tab}></Dashbord_Tutorilias_info_chart>
        












            <div className='w-full px-4 border-b-pink-700 border-b-2'>
            <div className=' flex flex-row-reverse items-center' >
                <div className='bg-[#FFC14C] mx-1 rounded-full w-[4vw] h-[4vw]'></div>
                <div className='' style={{fontSize:"1rem"}}>תרגול בוקר</div>
            </div>

            <div className=' flex flex-row-reverse items-center' >
                <div className='bg-[#4D4755] mx-1 rounded-full w-[4vw] h-[4vw]'></div>
                <div> תרגול ערב </div>
            </div>
            
            </div>
            <div className=' flex flex-col text-right items-end'>
                    <text>:תרגולים אפקטיבים</text>
                    {data?.data?.techEffectiveSuds?.nameTech!="null"&& <text className='w-fit rounded-lg mx-2 p-2 'style={{fontSize:"4vw"}} >דחק: {data?.data?.techEffectiveSuds?.nameTech}</text>}
                    {data?.data?.techEffectiveVas?.nameTech!="null"&&<text className='w-fit rounded-lg mx-2 p-2 'style={{fontSize:"4vw"}} >כאב: {data?.data?.techEffectiveVas?.nameTech}</text>}
                    {data?.data?.techEffectiveWell_being?.nameTech!="!null"&&<text className='w-fit rounded-lg mx-2 p-2 'style={{fontSize:"4vw"}} >רווחה נפשית: {data?.data?.techEffectiveWell_being?.nameTech}</text>}
                    {data?.data?.techEffectiveFatigue?.nameTech!="null"&&<text className='w-fit rounded-lg mx-2 p-2 'style={{fontSize:"4vw"}} >תשישות: {data?.data?.techEffectiveFatigue?.nameTech}</text>}
                    
            </div>
    </div>
  )
}

export default Dashbord_Tutorilias