import React from 'react'

const About = () => {
    return (
        <div className='h-screen flex flex-col overflow-y-auto p-2 px-6 whitespace-pre-line' style={{ background: "linear-gradient(0deg, #F0F0F0 0.06%, #F4F4F4 82.66%, #F6F6F6 100%)", direction: "rtl" }}>

            <h1 className='text-center text-2xl font-bold'>ברוכים הבאים למחקר COBMINDEX App!</h1>
            <p className='text-right py-2  ' >מחלת הקרוהן הינה מחלה כרונית השייכת למשפחת מחלות המעי הדלקתי. המחלה מאופיינת בהתלקחויות לצד הפוגות. כאשר המחלה בהתלקחות, חווים מטופלים רבים אי נוחות גדולה המתבטאת בכאבי בטן קשים, שלשולים, אנמיה, תשישות ועוד, ונדרשים לקחת תרופות להן תופעות לוואי על מנת להתמודד עם הקשיים. תסמיני מחלה אלו מקשים על התפקוד היומיומי ועלולים להשפיע על מרחבי חיים שונים כמו מרחב העבודה, הלימודים והמרחב החברתי.</p>
            <p className='text-right py-2  indent-4'> מחקרים שנערכו בקרב חולי קרוהן דיווחו כי המתמודדים עם מחלת הקרוהן חווים רמת דחק נפשי גבוהה יותר מאשר האוכלוסייה הכללית. עוד נמצא, כי מטופלים אלו מתמודדים גם עם רמות גבוהות של תסמיני חרדה ודיכאון הן בהתלקחות והן בהפוגה. </p>
            <p className='text-right py-2  indent-4'>מחלות המעי הדלקתיות סווגו לאחרונה כמחלות גוף-ונפש, בהן בא לידי ביטוי הקשר הדו-כיווני שבין מעיים-מוח או מוח-מעיים. סקירה שיטתית שנערכה בשנת 2022 ע"י בלאק ועמיתיו  ובדקה את תפקידו של הדחק הפסיכולוגי במחלות אלו מצאה שדחק מהווה גורם מקדים להתלקחות או החמרה של המחלה. ממצאים אלו ואחרים תומכים במגמה לשלב במסגרת הטיפול התרופתי במחלות המעי הדלקתיות גם התערבויות פסיכולוגיות קצרות טווח להתמודדויות עם דחק. </p>

            <p className='text-right py-2  indent-4 '>אחד המחקרים שהתפרסם בספרות המחקרית וביסס את חשיבות הקניית מיומנויות להתמודדות עם דחק בטיפול שניתן לחולים במחלת הקרוהן הוא מחקר COBMINDEX של צוות חוקרי אוניברסיטת  בן-גוריון בנגב ובית החולים האוניברסיטאי סורוקה. המחקר התקיים בין השנים 2018-2020 בסורוקה ובמרכז הרפואי רבין. המחקר הנוכחי מתבסס על שיטת COBMINDEX שנמצאה כיעילה במחקר הקודם.</p>

            <h1 className=' text-lg font-bold py-2'> תוצאות מחקר COBMINDEX הראשון</h1>
            <p className='text-right py-2 '>
                במחקר זה גייסנו 134 ישראלים בני 18 ומעלה המתמודדים עם מחלת הקרוהן ולהם רמת תסמינים קלה ובינונית. לימדנו אותם התערבות פסיכולוגית קצרת מועד בת שבעה מפגשים בזום. הגיל הממוצע של המטופלים עמד על  שנים 31, 75 היו נשים ו- 45 היו גברים. הזמן הממוצע של המחלה היה שש שנים, רוב משתתפי המחקר עבדו, היו בעלי השכלה גבוהה ולא עישנו.
            </p>
            <p className='text-right py-2  indent-4'>
                תוצאות של הניתוחים השונים שעשינו הראו, כי COBMINDEX הינה התערבות פסיכולוגית המעלה את רווחתם הנפשית של חולים בוגרים עם קרוהן להם מחלה קלה עד בינונית בתקופה קצרה של שלושה חודשים. תירגול של  COBMINDEX  היה קשור עם שימוש גבוה באסטרטגיות התמודדות יעילות (אסטרטגיות התמודדות ממוקדות רגש ובעיה) ועם שימוש מופחת באסטרטגיות התמודדות בלתי יעילות כמו האשמה עצמית. לאחר קבלת התערבות COBMINDEX דיווחו המטופלים על רמות גבוהות יותר של כישורי מיינדפולנס ורמות נמוכות יותר של מצוקה רגשית המתבטאות במדדי תשישות, דחק ותסמינים פסיכולוגים. ראינו גם שיפור קליני בפעילות המחלה וירידה במדדי דלקת, ערכי CRP וקלפרוטקטין. התערבות  COBMINDEX נמצאה יעילה במיוחד בקרב חולים עם קרוהן להם רמה גבוהה של מצוקה נפשית, רמה נמוכה של כישורי מיינדפולנס ורמת רווחה נפשית נמוכה. ההתערבות נמצאה יעילה בהפחתת כאב ותשישות בקרב המטופלים, דבר שהוביל בתורו לתפקוד טוב יותר במקומות העבודות ובתפקוד היומיומי. ההתערבות הייתה יעילה יותר בקרב נשים בהשוואה לגברים.
            </p>
            <p className='text-right py-2  indent-4'>מניתוחים של בדיקות הדם שסיפקו לנו המטופלים זיהינו שלחולים המתמודדים עם קרוהן יש פרופיל אימונולוגי המכיל רמות גבוהות של ציטוקינים המזוהים עם דחק פסיכולוגי ורמת מחלה גבוהה, פרופיל השונה מזה של אנשים בריאים. ציטוקין Il-18 נמצא כבעל מתאם לכל המאפיינים הפסיכולוגים של החולים, כאשר רמות גבוהות יותר של ציטוקין IL-18 נמצאו בקשר חיובי עם רמות גבוהות יותר של דחק ובקשר שלילי עם תפיסת איכות חיים, דבר המעיד על חשיבותו בתהליך הדלקתי. יתר על כן, שיפור במצבם הפסיכולוגי של המטופלים שקיבלו את התערבות COBMIDNEX היה במתאם שלילי עם שינויים דלקתיים ובשינוי הורמון הדחק קורטיזול. עוד זוהו מספר מרקרים אשר רמה גבוהה שלהם מנבאת שיפור במדדים פסיכולוגים לאחר קבלת התערבות  COBMINDEX. לבסוף, מבחינת דגימות מחיידקי המעי שהמטופלים סיפקו לנו עלו ממצאים ראשונים המעידים על קשרים בין המדדים הדלקתיים, מדדי הדחק הפסיכולוגי וחיידקי המעי כחלק מהשינויים שחלים עם מחלת הקרוהן, וכן שטיפול הCOBMINDEX- עשוי להשפיע באופן מיטיב על מדדים אלה. </p>

            <h1 className=' text-lg font-bold py-1'> מהי שיטת COBMINDEX?</h1>


            <p className='text-right py-2  '>שיטת COBMINDEX היא שיטת התערבות פסיכולוגית קצרת מועד המשלבת הקניית מיומנויות קוגניטיביות, התנהגותיות ומיינדפולנס להתמודדות עם דחק, תוך כדי תרגול יומי של מיומנויות אלו.</p>
            <p className='text-right py-2  indent-4'>השיטה משלבת מספר גישות תיאורטיות, ביניהן הגישה הקוגניטיבית התנהגותית הטוענת כי האינטראקציה בין גורמים קוגניטיביים, התנהגותיים, פיזיולוגים וסביבתיים משפיעה על תסמיני מחלה. למשל, כשאנחנו מתנהלים בחיינו מתוך <span className='font-bold  underline'>האמונה </span>
                שכאב הבטן הנורא שאנחנו חשים לעולם לא ייעלם או כשאנחנו <span className='font-bold  underline'>חושבים</span> שבכל רגע נתון נצטרך להתפנות בשירותים – אנחנו עשויים לנקוט <span className='font-bold  underline'>בהתנהגות</span> מסוג של הימנעות או התרחקות חברתית או <span className='font-bold  underline'>לחוש</span> דחק פסיכולוגי גבוה. הדחק מפעיל את מערכת העצבים האוטונומית שעלולה לאתגר או להפעיל את כאב הבטן או את הצורך ללכת לשירותים. אנו למעשה רואים כאן את הקשר שבין הגוף והנפש והחשיבות של הסתכלות ביו-פסיכו-סוציאלית בהתייחסות למחלת הקרוהן. מכאן, שלטובת ניהול טוב יותר של המחלה, חשוב שנלמד להבין את הקשר בין אירועים יומיומיים, מחשבות, רגשות, התנהגויות ותגובות פיזיות שלנו. </p>


            <p className='text-right py-2  indent-4'>במסגרת השיטה נלמד גם כיצד אנו משנים התנהגויות באמצעות תרגילי שחרור כגון נשימה, סריקת גוף ושחרור שרירים באופן שיטתי. מחקרים קודמים הראו, שחולים המתמודדים עם מחלות מעי דלקתיות הושפעו במיוחד מתרגולי נשימה מודעת שהשפיעה על המנגנונים המעורבים בהתהוות והתפתחות המחלה, כולל מערכת העצבים האוטונומית, דלקתיות וציר ההיפותלמוס-יותרת המוח-והאדרנל. מיומנויות אלו מפחיתות פעילות יתר של המערכת הסימפתטית, מעלות את התגובתיות של המערכת הפארא-סימפטטית ומפעילות גורמים אנטי-דלקתיים שמפחיתים דלקתיות ללא כל תופעות לוואי.</p>
            <p className='text-right py-2  indent-4'>נלמד גם לזהות דפוסי חשיבה והטיות קוגניטיביות שאינם מיטיבים עימנו, לאתגר דפוסים אלו עם עובדות ולהשתמש בדפוסים בריאים יותר. נלמד להשתמש במיומנויות של דמיון מודרך שיעזרו לנו להמיר את המחשבות שלנו למחשבות חיוביות תוך שימוש ב"חלימה" חיובית האפשרת תחושת שחרור מדחק. </p>
            <p className='text-right py-2  indent-4'>בנוסף, נלמד עקרונות בסיסיים של מיינדפולנס ומדיטציות. מיינדפולנס היא צורה של אימון מנטלי שמטרתו הפחתת תגובתיות התנהגותית בעקבות גירויים. באמצעות המדיטציה, האדם מתבונן בגירויים חיצוניים ופנימיים המתחלפים באופן תדיר ומתרגל נכונות לחוות את הרגע – עם מה שמגיע איתו ללא שיפוטיות, ללא הערכה או ניסיון לשנות את החוויה.</p>


            <h1 className=' text-lg font-bold py-1'> חשיבות התרגול היומיומי</h1>
            <p className='text-right py-2  '>בשיטת COBMINDEX אנחנו שמים דגש משמעותי על תרגול יומיומי של המיומנויות הנלמדות. ממחקרינו הקודמים ראינו והבנו כי רק אלו שתרגלו באופן שיטתי מדי יום - דיווחו על שינוי גדול בהפחתת תסמינים של דכאון, דחק, חרדה ותחושות כאב כמו גם על שיפור בהתמודדות עם מחלת הקרוהן וניהולה. כל זה היטיב את איכות החיים שלהם ושיפר את בריאותם ושביעות הרצון מחייהם. אנו ממליצים לתרגל פעמיים ביום, כל תרגול בסביבות עשר דקות.</p>
            <p className='text-right py-2  '>חשוב לנו להדגיש, כי ללא תרגול יומיומי נאלץ להוציא אתכם מהמחקר.</p>

            <h1 className=' text-lg font-bold py-1'> כללים בסיסיים לתרגול יעיל</h1>
            <ul className='flex flex-col '>
                <il className="before:content-['✓'] ">קיבעו מקום קבוע לתרגול היומי. ישיבה באותו המקום מסייעת ביצירת קביעות ושגרה בתרגול. ודאו שזה מקום בו אתם יכולים לשבת בשקט וללא הפרעה. כדאי שזה יהיה מקום בו אתם חשים בטוחים ונינוחים. </il>
                <il className="before:content-['✓'] ">משך התרגול הוא בסביבות 10 דקות.</il>
                <il className="before:content-['✓'] ">השתמשו בשעון למדידת הזמן. אַל תשבו כמה זמן שמרגיש "נכון". גם אין צורך לנחש אם "עבר הזמן". שבו עם שעון מעורר או השתמשו באפליקציות מהסוג של <a className="underline" target="_blank" href='https://insighttimer.com' >Insight Timer</a> אשר בהן יצלצל הגונג בסיום הזמן שהגדרתם.</il>
                <il className="before:content-['✓'] ">שבחו את עצמכם על כך שאתם מפנים את הזמן לתרגול ולהשקעה בבריאותכם. הדבר אינו מובן מאליו. החליטו במודע, שכל מה שיקרה במהלך התרגול הוא "בסדר גמור". אתם בתהליך שיפור ואימון. החלטה זו מורידה את תחושת הדחק והתובענות מעצמינו.</il>

            </ul>
            <h1 className=' text-lg font-bold py-1'> COBMINDEX במחקר הנוכחי – כיצד זה יתבצע?</h1>
            <p className='text-right py-2  '>כאמור, ההתערבות הפסיכולוגית COBMINDEX כוללת למידת גישות התנהגותיות, קוגניטיביות ומיינדפולנס תוך שילוב של תרגול יומיומי של המיומנויות הנלמדות.</p>
            <p className='text-right py-2  indent-4'>לצורך למידת ההתערבות, חילקנו אותה למסלול המורכב מארבע יחידות. מעבר מיחידה ליחידה מותנה במעבר של פרק זמן (שבוע/שבועיים/שלושה שבועות – לפי היחידות השונות) ובביצוע מינימום תרגולים יומיים של המיומנויות שנלמדו באותה יחידה. המידע הזה מפורט לכם כאן וגם בדף הבית באפליקציה.</p>
            <p className='text-right py-2  indent-4'>היחידה הראשונה היא יחידת המיומנויות השייכות לגישות ההתנהגותיות. ביחידה זו נלמד כמה סוגים של נשימות, נלמד לסרוק בדמיון את אברי הגוף שלנו ולהרפות קבוצות שרירים שאנו מרגישים בהם מתח. תרגולים אלו הם תרגולים פשוטים מאד וקלים ללמידה. על משתתפי המחקר ללמוד את המיומנויות ולתרגלן במשך שבוע ימים, פעמיים ביום. מינימום ארבעה תרגולים עבור כל מיומנות – כדי שיוכלו להמשיך במחקר ולעבר לתרגל את היחידה השנייה. </p>
            <p className='text-right py-2  indent-4'>היחידה השנייה היא יחידת מיומנויות הדמיון המודרך. משתתפי המחקר ילמדו איך לתרגל את הדמיון על מנת לחוש טוב יותר. משתתפי המחקר ילמדו לבנות בדמיונם מקום בטוח, שליו ורגוע, כזה שיוכל ללוות אותם במצבים בהם הם נזקקים לרוגע ושלווה. משתתפי המחקרים הקודמים שלנו אהבו את המקום הבטוח שהם יצרו לעצמם. כל אחד דמיין מקום אחר, היה מי שדמיין מקום בטוח ורגוע בטבע ואחר דמיין מקום כזה בחדר השינה שלו. נלמד גם השימוש בדמיון המודרך על מנת להפחית כאב או תחושות קשות. למידה ותרגול של מיומנויות השימוש בדמיון מודרך אף היא מתוכנתת לפרק זמן של שבעה ימים בהם משתתפי המחקר מתבקשים לתרגל גם את המיומנויות החדשות מעולם הדמיון המודרך וגם לשלב את המיומנויות ההתנהגותיות שרכשו ביחידה ההתנהגותית הקודמת. יש לתרגל מינימום 4 פעמים כל מיומנות של דמיון מודרך בפרק זמן של שבוע כדי שהיחידה השלישית תפתח למשתתף המחקר.</p>
            <p className='text-right py-2  indent-4'>היחידה השלישית במחקר, שתארך שלושה שבועות, תלמד את משתתפי המחקר מיומנויות שיסייעו להם לחשוב אחרת ובצורה שיותר נוח לנהל את מחלת הקרוהן ובכלל להתנהל בחיים בכל המרחבים המשמעותיים: יחסים בין אישיים, קריירה ומשפחה. בתחילה ילמדו משתתפי המחקר לזהות  דפוסי חשיבה לא יעילים ולא סתגלניים. למשל במצבים בהם הם עסוקים בהאשמה עצמית על הכאב שהם חווים או חשים מדוכדכים ומיואשים מחייהם. בהמשך, נלמד לחשוב אחרת ולדבר עם עצמנו באופן יותר תומך ופחות מאשים וכועס. ליחידה זו של למידה ותרגול קוגניטיביים מוקדשים שלושה שבועות. גם כאן, יהא עליכם לתרגל את המיומנויות החדשות וגם להמשיך ולתרגל את המיומנויות הקודמות שרכשתם. </p>
            <p className='text-right py-2  indent-4'>לבסוף נלמד מדיטציות מעולם המיינדפולנס. מיינדפולנס פרושו קשיבות, קשיבות לעצמינו ולמה שקורה מסביבנו מבלי להיות שיפוטיים ומבלי שעלינו לפעול באופן מידי. נלמד להיות קשובים לכאן ועכשיו, לבחון את רגשותינו ומחשבותינו בריחוק מסוים ולאמץ חמלה כלפי עצמנו וסביבתנו. ליחידה זו מוקצבים שבועיים ימים בהם תתרגלו את המדיטציות החדשות לצד מיומנויות קודמות שלמדתם.</p>



            <h1 className=' text-lg font-bold py-1'>מי הם המטפלים המעבירים את התערבות COBMINDEX?</h1>
            <p className='text-right py-2  '>משתתפי המחקר שהתגייסו למחקרנו חולקו באופן רנדומלי לשתי קבוצות מחקר: קבוצת המטפל הדיגיטלי וקבוצת המטפל האנושי.</p>
            <p className='text-right py-2  indent-4'>בקבוצה הראשונה, <span className='font-bold '>קבוצת המטפל הדיגיטלי</span>, המטופלים לומדים את המיומנויות ומתרגלים אותן באופן יומי עם מטפלת דיגיטלית המלווה את תהליך ההתערבות שלהם באפליקציה. המטפלת הדיגיטלית מתקשרת עם המטופל באמצעות הסכתים (פודקאסטים) ומשובים למידע שהמטופל מעלה לאפליקציה. </p>

            <h1 className=' text-lg font-bold py-1'> מה מקבלים המטופלים השייכים לקבוצה זו:</h1>
            <ul className='flex flex-col '>
                <il className="before:content-['✓'] ">מסגרת להתקדמות בתהליך מובנה של למידה ותרגול (אפליקציה)</il>
                <il className="before:content-['✓'] ">הדרכות על מיומנויות להפחתת תחושות דחק (מסך הדרכות)</il>
                <il className="before:content-['✓'] ">הנחיות לתרגולים של המיומנויות הנלמדות (תרגולים)</il>
                <il className="before:content-['✓'] ">ניטור תחושות המטופל לפני ואחרי התרגולים לאורך זמן וסיכומי פעילות ומדדים</il>
                <il className="before:content-['✓'] ">תזכורות לתרגול </il>
                <il className="before:content-['✓'] ">תחושה שאתם לא לבד. שכן צוות של ניהול ההתערבות הפסיכולוגית מנטר את התקדמותכם ומצבכם.</il>

            </ul>

            <p className='text-right py-2  indent-4'>בשבעת השבועות הראשונים של ההשתתפות במחקר, על המטופל להאזין להסכתים על פי תוכנית ההתערבות ולתרגל את המיומנויות תוך שימוש בהנחיות של המטפלת הדיגיטלית והרצת ההסכתים. כל יחידה מכילה מספר מיומנויות ללמידה, כאשר עליכם להקשיב להדרכה על המיומנות החדשה ולאחר מכן לתרגל את המיומנות, כפי שמופיע במסך ההדרכות. לאחר שמתרגלים את המיומנות – נפתחות במסך התרגולים ואריאציות לתרגול של אותה מיומנות. מיומנות זו בשילוב עם המיומנויות הקודמות שלמדתם צריכות להיות מתורגלות במהלך הזמן שיש לכם לסיים את היחידה הנלמדת. </p>
            <p className='text-right py-2  indent-4'>כך למשל, הגעתם לשבוע השני של המחקר וסיימתם ללמוד ולתרגל את היחידה הראשונה של המיומנויות ההתנהגותיות, כעת תלמדו מההדרכה של המיומנות הבאה של דמיון מודרך את המיומנות הראשונה שנקראת "מקום בטוח". לאחר שמיעת ההדרכה - תמשיכו לתרגל את המיומנות הזו. לאחר שתסיימו לתרגל מיומנות זו – תפתחנה לכן אופציות לתרגול נוספות ומגוונות של אותה מיומנות של מקום בטוח, תוכלו לראות אותן במסך התרגולים. מסך זה יכיל גם את המיומנויות ההתנהגותיות שלמדתם בשבוע הקודם. עליכם להמשיך לבחור לתרגל גם מהמיומנויות הישנות וגם מהמיומנויות החדשות.</p>
            <p className='text-right py-2  indent-4'>לאחר סיום שלב למידת המיומנויות, כעבור כשבעה שבועות מתחילת הלמידה - כל המיומנויות שנלמדו תהנה פתוחות ונגישות לתרגול והמטופל יבחר מה הוא מתרגל על פי רצונו.</p>
            <p className='text-right py-2  indent-4'>בקבוצת המחקר השנייה, <span className='font-bold '>קבוצת המטפל האנושי</span>, המטופלים לומדים את אותה שיטת COBMINDEX בשבעה מפגשי זום עם מטפל או מטפלת אנושיים. שתי הקבוצות לומדות בדיוק את אותו פרוטוקול של השיטה.
                כל מפגש זום יארך כשעה, בשעה שמתאימה למטופל ולמטפל/ת ובמהלך המפגשים תלמדנה ותתורגלנה בדיוק אותן מיומנויות שילמדו ויתורגלו ע"י המטפלת הדיגיטלית.  לאחר סיום שבעת המפגשים, המטופלים ממשיכים לעבוד רק עם האפליקציה.
            </p>
            <p className='text-right py-2  indent-4'>מטופלים מקבוצה זו אף הם מקבלים את האפליקציה – אולם רק לטובת התרגול בבית. באמצעות האפליקציה הם מתבקשים לתרגל ביחד עם המטפלת הדיגיטלית את המיומנויות שלמדו עם המטפלת האנושית בפגישות הזום. מטופלים אלו לא לומדים את המיומנויות מהמטפלת הדיגיטלית אבל כן מתרגלים ביחד איתה. אנו מבקשים להקפיד לתרגל לפי תוכנית המחקר אותה תקבלו בפגישות הזום.
                חשוב להקפיד שמטופלים בקבוצה זו יתחילו לעבוד עם האפליקציה כבר ביום הפגישה עם המטפלת האנושית.
            </p>


            <h1 className=' text-lg font-bold py-1'>מה מקבלים המטופלים השייכים לקבוצה זו:</h1>
            <ul className='flex flex-col '>
                <il className="before:content-['✓'] "> מסגרת להתקדמות בתהליך מובנה של למידה ותרגול</il>
                <il className="before:content-['✓'] ">	7 פגישות זום ללמידת פרוטוקול השיטה עם מטפל אנושי</il>
                <il className="before:content-['✓'] ">	מסגרת מובנית לתרגול מיומנויות השיטה (אפליקציה)</il>
                <il className="before:content-['✓'] ">	הנחיות לתרגולים מגוונות של המיומנויות הנלמדות (תרגולים)</il>
                <il className="before:content-['✓'] ">	ניטור תחושות המטופל לפני ואחרי התרגולים לאורך זמן וסיכומי פעילות ומדדים</il>
                <il className="before:content-['✓'] ">	תזכורות לתרגול </il>
                <il className="before:content-['✓'] ">	תחושה שאתם לא לבד. שכן צוות של ניהול ההתערבות הפסיכולוגית מנטר את התקדמותכם ומצבכם. </il>

            </ul>



            <h1 className=' text-lg font-bold py-1'>הסבר על המדדים היומיים</h1>
            <p className='text-right py-2  '>מאחר ואנו חוקרים ומנטרים את השפעת תרגול המיומנויות על תחושותיכם, בכל תרגול שאתם מבצעים תתבקשו להכניס נתונים לגבי תחושותיכם לפני התרגול ולאחריו. הסבר על המדדים תוכלו למצוא בהמשך דף זה.</p>
            <p className='text-right py-2  indent-4'>על מנת שנוכל לאמוד את השפעת המיומנויות שנלמד אתכם על תחושותיכם וגם כדי שאתם תלמדו לכמת את תחושותיכם, נבקש מכם בכל תרגול למלא ארבע מדדים פשוטים. שלושת המדדים הם מדדים סובייקטיביים, אין בהם "נכון" או "לא נכון". ואתם תתבקשו למלא אותם לפי מה שתחושו, ותרגישו באותו רגע. הדיווח הוא מספרי וכל מדד נע בין 0 ל-10.</p>
            <p className='text-right py-2  indent-4'>המדד הראשון הוא מדד של <span className='font-bold '>דחק נפשי</span>. מדד זה בוחן את עוצמת תחושת אי הנוחות הרגשית והחוויה הפנימית הסובייקטיבית שלכם ברגע נתון. דחק גבוה עלול לבוא לידי ביטוי בתחושות חרדה, כעס, עצבנות, ומתח. הציון 0 משמעו 'אני כלל לא חש/ה דחק'. הציון 10 משמעו דחק נפשי גבוה מאד. לפני ואחרי כל תרגול של מיומנות, אתם תתבקשו למלא מדד זה.</p>
            <p className='text-right py-2  indent-4'>המדד השני הוא מדד <span className='font-bold '>התשישות</span>. עד כמה אתם מרגישים ורואים עצמכם מותשים. אין הכוונה לעייפות שהינה תחושה טבעית שנועדה לסמן לגוף להפחית את רמת הפעילות כדי שיוכל לחדש את מצבורי האנרגיה שלו אלא תחושת עייפות שאינה פרופורציונלית לרמת המאמץ, אינה חולפת עם מנוחה ומקשה מאד על התפקוד היומיומי. גם כאן הציון ניתן מ-0 עד 10,  כש-0 משמעו 'כלל לא מותש' ו-10 משמעו 'מאד מותש'. אתם תתבקשו לדרג מדד זה לפני ביצוע כל תרגול ולאחריו.</p>
            <p className='text-right py-2  indent-4'> 	המדד השלישי הוא מדד <span className='font-bold '>הכאב</span>. עד כמה אתם חשים כאב פיזי כתסמין של מחלת הקרוהן. הציון ניתן מ-0 עד 10,  כש-0 משמעו 'כלל לא חש כאב' ו-10 משמעו 'מאד כואב לי'. האפליקציה תבקש מכם לדרג את רמת הכאב שאתם חשים לפני ואחרי כל תרגול מיומנות.</p>
            <p className='text-right py-2  indent-4'>המדד הרביעי אותו תתבקשו לדרג מדי תרגול הוא מדד <span className='font-bold '>הרווחה הנפשית</span>. במדד זה תבצעו הערכה סובייקטיבית ברגע נתון על בריאותכם הנפשית ותדרגו עד כמה אתם חשים כי איכות חייכם חיובית ורצויה.
                המדד הזה הוא גם מספרי אך <span className='font-bold '>הפוך</span> בכיוון שלו משלושת המדדים הקודמים. רווחה נפשית 0 משמעה כי אין לכם כל רווחה נפשית רמה של 10 משמעה שאתם חשים רווחה נפשית גבוהה מאד. לפני ואחרי כל תרגול תתבקשו לדווח על רווחתכם הנפשית באותו רגע.
            </p>
            <p className='text-right py-2  indent-4'>ארבעת המדדים הללו ילוו אתכם לאורך כל השתתפותכם במחקר שלנו. כך נוכל ללמוד מכם ואתכם למדו את עצמכם ואת התנודות במדדים השונים. תוכלו לבחון את הקשרים בין השינויים שתחוו לבין סוגי המיומנויות השונות שתלמדו. מאד חשוב כי תקפידו על תרגול יומיומי כפעמים ביום. אנחנו יודעים מהמחקרים שלנו ושל אחרים כי התרגול היומיומי והדיווח על המדדים קשורים זה לזה. מי שמתרגל מרגיש שפור ברווחה הנפשית, ורואה עצמו פחות בדחק נפשי, בכאב וחש פחות תשישות.</p>

            <h1 className=' text-lg font-bold py-2'>מה מצופה ממטופלי המחקר</h1>
            <ul className='flex flex-col '>
                <il className="before:content-['✓'] ">	תרגול פעמיים ביום, בסביבות  10 דקות כל פעם – תוך שימוש באפליקציה ושמיעת ההסכתים.</il>
                <il className="before:content-['✓'] ">	דיווח על התחושות לפני ואחרי כל תרגול </il>
                <il className="before:content-['✓'] ">	הימנעות מהעברת האפליקציה לאחר</il>
                <il className="before:content-['✓'] ">	תרגול לאורך כל שנת המחקר</il>

            </ul>
            <p className='text-right py-2  '>במידה ותתקלו בבעיות טכניות או קשיים אחרים, אתם מוזמנים לפנות בכתב למייל <a href='mailto:CrohnMind@gmail.com' className='underline'>CrohnMind@gmail.com</a> עם פרטים מזהים ואנו ניצור עמכם קשר ונסייע.
                במקרה חירום ניתן לכתוב ווצאפ לד"ר גנית גורן, מנהלת ההתערבות הפסיכולוגית, בטלפון
                <a href='tel:054-4682007' className='underline'>054-4682007</a>
            </p>

            <p className='whitespace-pre-line'></p>
            <p className='text-right p-4 text-lg indent-4'></p>


        </div>
    )
}

export default About