import React from 'react'
import icon_night1 from "../Images/Home/Home_bubble_botoom/Moon/icon_night1.svg"
import icon_night2 from "../Images/Home/Home_bubble_botoom/Moon/icon_night2.svg"
import icon_night3 from "../Images/Home/Home_bubble_botoom/Moon/icon_night3.svg"
import icon_night4 from "../Images/Home/Home_bubble_botoom/Moon/icon_night4.svg"
import icon_night5 from "../Images/Home/Home_bubble_botoom/Moon/icon_night5.svg"

import icon_sun1 from "../Images/Home/Home_bubble_botoom/Sun/icon_day1.svg"
import icon_sun2 from "../Images/Home/Home_bubble_botoom/Sun/icon_day2.svg"
import icon_sun3 from "../Images/Home/Home_bubble_botoom/Sun/icon_day3.svg"
import icon_sun4 from "../Images/Home/Home_bubble_botoom/Sun/icon_day4.svg"
import icon_sun5 from "../Images/Home/Home_bubble_botoom/Sun/icon_day5.svg"


import icon_time1 from "../Images/Home/Home_bubble_botoom/Time/icon_min1.svg"
import icon_time2 from "../Images/Home/Home_bubble_botoom/Time/icon_min2.svg"
import icon_time3 from "../Images/Home/Home_bubble_botoom/Time/icon_min3.svg"
import icon_time4 from "../Images/Home/Home_bubble_botoom/Time/icon_min4.svg"
import icon_time5 from "../Images/Home/Home_bubble_botoom/Time/icon_min5.svg"

import tip_BG from "../Images/Home/Home_bubble_botoom/Tip_BG 2.svg"
const Home_bubble_botoom = ({attributeCompleteDailyPractice,index}) => {
    const icon_nights=
    {1:icon_night1,
    2:icon_night2,
    3:icon_night3,
    4:icon_night4,
    5:icon_night5}
  
    const icon_suns=
    {1:icon_sun1,
    2:icon_sun2,
    3:icon_sun3,
    4:icon_sun4,
    5:icon_sun5}

    const icon_times=
    {1:icon_time1,
    2:icon_time2,
    3:icon_time3,
    4:icon_time4,
    5:icon_time5}
  
    return (
    // אפשר לעשות W-VW ואפשר למחוק
    <div className=' bg-white rounded-2xl m-3 pt-3 pb-4'>
        <div className='w-full px-5 flex justify-end'>
            <p className='font-Hebbo  font-medium text-black  ' style={{fontSize:"5vw"}}    >:תרגול יומי</p>
        </div>
        
        
            {/* <div className='w-full px-6'> 
                <p className='w-[84vw] h-[10vh] text-center bg-tahiti rounded-xl px-20'>עלייך לתרגל לפחות פעמיים ביום 
    למשך 20 דקות (סה״כ)</p>
            </div> */}
        <div className=' p-2'>
        <img className='w-full' src={tip_BG} alt="" />
        </div>


        <div className='w-full px-4 flex justify-end'>
            <p className='font-Hebbo font-medium text-black ' style={{fontSize:"5vw"}}>:להשלמת התרגול היומי</p>
        </div>
        
        
        <div className=' flex flex-row justify-end p-2  divide-x-2'>
            
            
            <div className='  flex flex-row'>
              
            <div className=' flex flex-col '>
                    <p className='text-right font-bold font-Hebbo' style={{fontSize:"7vw"}} > {attributeCompleteDailyPractice?.completeEvening}</p>
                    <p style={{fontSize:"3vw"}}>תרגולים</p>
                </div>
              
              
               <div>
                       <img className='w-[6vw] mx-2 m-1' src={icon_nights[index]} alt="as" /> 
                </div>              
            </div>


            <div className='  flex flex-row'>
              
              <div className=' flex flex-col pl-2'>
                      <p className='font-Hebbo text-right font-bold' style={{fontSize:"7vw"}}> {attributeCompleteDailyPractice?.completeMorning}</p>
                      <p className='text-right ' style={{fontSize:"3vw"}}>תרגולים</p>
                  </div>
                
                
                
                 <div>
                         <img className='w-[6vw] mx-2 m-1' src={icon_suns[index]} alt="as" /> 
                  </div>
                  
                  
                
              </div>

              <div className='  flex flex-row'>
              
              <div className=' flex flex-col pl-2 '>
                      <p className='font-Hebbo text-right font-bold' style={{fontSize:"7vw"}}>  {attributeCompleteDailyPractice?.completeMin}</p>
                      <p style={{fontSize:"3vw"}}>דקות</p>
                  </div>
                
                
                 <div>
                 <img className='w-[4vw] mx-2 m-1' src={icon_times[index]} alt="as" /> 
                  </div>
              </div>

            

        </div>
    </div>
  )
}

export default Home_bubble_botoom