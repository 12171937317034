import React, { useState  , useEffect} from 'react'
import Dashboard from '../Pages/Dashboard'
import VideoBig from './VideoBig'
import {  useParams } from 'react-router-dom'
import  {useHistory , useLocation }  from "react-router-dom";
import { type } from '@testing-library/user-event/dist/type';
// {ActionId , fileName}

const GuildFrame = () => {

    const navigate = useHistory()
    const urlVideo = localStorage.getItem('urlVideo')
    const urlImage = localStorage.getItem('urlImage')
    const description = localStorage.getItem('description')
    
    const levelId= localStorage.getItem('levelId')
    const {actionId} = useParams()
    //const {fileName} = useParams()

    if ((actionId == null) || (urlVideo == null) || (levelId==null)||(urlImage == null))
    {
        //navgite
        // return navigate..
        // navigate.replace('/Tutorilias')
        navigate.goBack();
    }
  

    const [actionIdBuild , setActionIdBuild] = useState(actionId)
    const [videoUrl , setVideoUrl ] = useState(urlVideo)

    const [currentPage , setCurrentPage ] = useState('video')


    useEffect(()=>
    {
        if(currentPage === 'Finish')
        {
            handleQuestSeconedNext()
        }
    } , [currentPage])

    // const handleQuestFirstNext = () =>
    // {
    //     setCurrentPage('video')
    // }
    // const handleVideoNext = () =>
    // {
    //     setCurrentPage('q2')
    // }
    const handleQuestSeconedNext = () =>
    {
        // setCurrentPage('summary')
        // navigate.replace('/Tutorilias')
        navigate.goBack();
       

    }

    return (

    <div className='h-full overflow-y-auto'>
     
      {currentPage === 'video' && <VideoBig actionIdBuild = {actionIdBuild} levelId={levelId} description={description} type = {"Guild"} setCurrentPage = {setCurrentPage}
                                            videoUrl={urlVideo} urlImage ={urlImage}/>}
      {/* {currentPage === 'summary' && <summary message={message} />} */}
    </div>


  )
}

export default GuildFrame