import React from 'react'

const Dashbord_Tutorilias_Bar = ({morning, evening}) => {
 console.log(morning,evening)
    return (
    <div className='flex flex-col h-[100%] w-2 justify-end   '>
        <div className={`bg-[#4D4755] w-full rounded-t-lg`} style={{height:`${evening}%`}}>

        </div>
        <div className={`bg-[#FFC14C] ${evening==0?"rounded-t-lg":""} w-full` }style={{height:`${morning}%`}}>

        </div>

    </div>
  )
}

export default Dashbord_Tutorilias_Bar