import {React,useState} from 'react'
import Arrow_down from '../../Images/Daily summary/Top/arrow-down.svg'
import Arrow_side from '../../Images/Daily summary/Top/arrow-side.svg'


const Daily_summary_List_Tutorilias = ({exercisesSession}) => {
  const [hide,SetHide]=useState(false);
  return (
    <div className=' bg-white p-2 flex flex-col rounded-2xl '>
        
        <div className='flex justify-between border-b-2' style={{borderImage: "linear-gradient(45deg, rgba(225, 35, 70, 1) , rgba(118, 85, 153, 1)) 1 "}}>
        <button><img className='w-[10px] mx-4' src={!hide? Arrow_side :Arrow_down} alt="sda" onClick={()=>{SetHide(prev=>!prev)}}/>
        </button>
            <p className='font-bold' style={{fontSize:"5vw"}}>:תרגולים</p>
            
        </div>
        <div className={hide?'text-right':"hidden"}>
          {exercisesSession?.map((row)=>{return(
   
            <div className='flex justify-between'>
                <p className='w-[15%]  'style={{fontSize:"5vw"}}>דקות   </p>
                <p className='w-[5%]  'style={{fontSize:"5vw"}}> {row?.rangeMin}  </p>
                <p className='w-[80%]  pl-6'style={{fontSize:"5vw"}}> {row?.nameAction} </p>
            </div>
            )})}
        </div>
    </div>
  )
}

export default Daily_summary_List_Tutorilias