import React from 'react'

const Bar_Gauge = ({q1, q2}) => {

    if(q1 == null)
    {
      return null; 
    }
    if(q2 == null)
    {
      return null;
    }

    const delta=(q1-q2)
    var min=q2
    var max=q1
    if (delta<0)
    {
       min=q1 
       max=q2}
       console.log("q1: " + q1 + " q2:" + q2)

  return (
    <div className= {`${delta>4? "bg-[#009ED1]": delta>2?"bg-[#69B25D]":delta>0?"bg-[#EBDA51]":delta==0?"bg-amber-400":"bg-[#FA6B38]"} w-full  absolute rounded-md`} style={{height:`${Math.abs(delta)*9.8+2}%`,top:`${(10-max)*9.7+1}%`}} ></div>
  )
}

export default Bar_Gauge