import React, { useEffect } from 'react'
import { useState } from 'react'
import Arrow_DarkGrey_right from "../Images/Arrows/arrow_DarkGrey-right.svg"
import Arrow_DarkGrey_down from "../Images/Arrows/arrow_DarkGrey-down.svg"
import GreenV from "../Images/Icons/GreenV.svg"
import Action from './Action'
import Icon_lock  from "../Images/Icons/icon_lock.svg"

const SubPractice = ({item,index}) => {
    const [hide,SetHide]=useState(false);
    console.log(item)


    useEffect(()=>
  {
    if(localStorage.getItem("techName") != null)
    {
      
      const ind=localStorage.getItem("techName")
      if(ind==item.nameTech){
      SetHide(true)
      localStorage.removeItem("techName")

      }
    }

  } , [] )

    
  return (
    <div className='w-full bg-white   flex flex-col  divide-y-4 '>
    {/* <div className=' flex flex-row justify-between items-center   h-[50px]'>   */}
    <div className=' flex flex-row justify-between items-center   h-fit mt-1 '>  
     {item.status=="Lock"?
     <img className='w-[10px] mx-4' src={Arrow_DarkGrey_right} alt="sda" />:  
    <img className='w-[10px] mx-4' src={!hide? Arrow_DarkGrey_right :Arrow_DarkGrey_down} alt="sda" onClick={()=>{SetHide(prev=>!prev)}}/>  
  }
    <p className='w-full text-xl text-right '>{item.nameTech}</p>
    {item.status=="Finish"?<img className='w-[17px] mx-4' src={GreenV} alt="sda"/>:item.status=="Lock"?
    <img className='w-[17px] mx-4' src={Icon_lock}alt="sda"/>
    :<div className='w-[17px] h-[13px] mx-5'></div>} 
    </div>   
    <div className={hide?"  flex flex-col justify-between items-center  divide-y-2  ":'hidden '}>
    {item.tutorialsActions?.map((action,)=>{return(
    <Action techname = {item.nameTech} item={action} index={index + 1}/>)})}
        {/* <Action item={item}/>
        <Action item={item}/> */}
    </div>
    </div>
  )
}

export default SubPractice