import React, { useState } from 'react'
import Nav_home from "../Images/Nav/home.svg"
import Nav_profile from "../Images/Nav/profile.svg"
import Nav_dashboard from "../Images/Nav/dashboard.svg"
import Nav_training from "../Images/Nav/training.svg"
import Nav_practice from "../Images/Nav/practice.svg"
import { Link } from 'react-router-dom'
import Cookies from "universal-cookie"
import { NavLink } from 'react-router-dom'

const Nav = (props) => {

  const cookies = new Cookies()
  const load = cookies.get('groupType'); 
  const [ groupType , setGroupType ]  = useState(load ? cookies.get('groupType') : null)

  return (
    // <div id="Nav" className='flex flex-row justify-between  bottom-0   left-0  w-full h-18  border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600 bg-gradient-to-r opacity-1 to-ma from-0% from-tahiti to-99%'>
    <div id="Nav" className='flex flex-row justify-between  h-[10%]  w-full  border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600' style={{background: "linear-gradient(to right,rgba(231, 33, 68, 0.05), rgba(118, 84, 158, 0.05))"}}>
      <NavLink to="/Profile" id="profile" className={(isActive ) => (isActive ?'m-2 bg-white rounded-2xl':'m-2')}>
          <img src={Nav_profile}  alt="profile" className='w-[8vh] '/>
      </NavLink>
      
      {/* <Link to="/Profile" id="profile" className='m-2'>
          <img src={Nav_profile}  alt="profile" className='w-[8vh] 'style={{background: Index_Nav =="1"?"white":"none" ,borderRadius:"16px"}} onClick={()=>{SetIndex_Nav(1)}}/>
      </Link>
       */}
      <NavLink to="/Dashboard" id="dashboard " className={(isActive ) => (isActive ?'m-2 bg-white rounded-2xl':'m-2')}>
            <img src={Nav_dashboard} alt="dashboard"  className='w-[8vh]'/>
        
      </NavLink>
      
        <NavLink to="/Map" id="practice" className={(isActive ) => (isActive ?'m-2 bg-white rounded-2xl':'m-2')} >
        <img src={Nav_practice} alt="practice" className='w-[8vh] ' />
        </NavLink> 


        {groupType !== 'C' && (
      <NavLink to="/Tutorilias" id="Tutorilias" className={(isActive ) => (isActive ?'m-2 bg-white rounded-2xl':'m-2')}  > 
      <img src={Nav_training} alt="tutorilias" className='w-[8vh]'/>
        </NavLink>)}
        
        <NavLink to="/Home" id="home" className={(isActive ) => (isActive ?'m-2 bg-white rounded-2xl':'m-2')}>
        <img src={Nav_home} alt="home" className='w-[8vh]' />
        </NavLink>



    </div>
  )
}

export default Nav