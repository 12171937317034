import React, { useEffect , useState } from 'react'
import Body_home from '../Components/Body_home'
import axios from 'axios'
import useAuth from '../Hook/useAuth'
import  {useHistory , useLocation }  from "react-router-dom";
import { useQuery } from "react-query";

const Tutorilias = () => {
  

  const { auth , logout , setAuth } = useAuth()
  const navigate = useHistory()

  const { isLoading, isError, data, error, refetch } = useQuery(["tutorials"], () =>
      axios.get(`function_patient/tutorials` ,
      { 
      headers: { 'Content-Type': 'application/json'}
      } ,
      {'Access-Control-Allow-Credentials': true}))

    // if (isLoading) return "Loading...";

    if (error) 
    {
      if(!auth)
      {
        navigate.replace('/Login')
      }
      return "An error has occurred: " + error.message;
    } 
     console.log(data?.data)

     const techNameSelected =  localStorage.getItem('techName')
     const levelIdSelected =  localStorage.getItem('levelId')
     console.log("techNameSelected : "  + techNameSelected + " - " + "levelIdSelected : "  + levelIdSelected)
  return (
      <div className='h-full flex flex-col overflow-y-auto bg-grand'
      // style={{background: "linear-gradient(to bottom,rgba(67, 161, 206, 0.35), rgba(91, 186, 159, 0.35),rgba(245, 245, 245, 0.35))"}}
      style={{background: "linear-gradient(to bottom,rgba(244, 244, 244, 1), rgba(240, 240, 240, 1))"}}
      // rgba(244, 244, 244, 1)
      // rgba(240, 240, 240, 1)
      >
    {/* <Header/> */}
    <div className='flex flex-row-reverse  '>
    {/* <h1 className="flex justify-center items-center w-full font-bold text-2xl text-tahiti">הדרכות</h1> */}
    <h1 className="flex justify-center items-center w-full font-Hebbo font-bold text-3xl text-black mt-3">הדרכות</h1>
        {/* <img className='w-12 h-12' src={filter} alt="xx" /> */}
    </div>
    <div className=''>
    <div class=" h-fit">
    <div className='p-2'>
    {/* {list.map((row)=>(<Trainings_List item={row}/>))} */}
    <Body_home  stages={data?.data}/>
      
      
      
      {/* <Trainings_List Video="Vidoe1" main_title="מומלצים בשבילך"/>
       <Trainings_List main_title="נשימה"/>
       <Trainings_List/>
       <Trainings_List/> */}
       
     </div>
     </div>
  </div>
  {/* <Nav num="3"/> */}
  </div>
  )
}

export default Tutorilias