import React, { useState  , useEffect} from 'react'
import PracticeVideos from '../Pages/PracticeVideos'
// import Dashboard from '../Pages/Dashboard'
import VideoBig from './VideoBig'
import {  useParams } from 'react-router-dom'
import  {useHistory , useLocation }  from "react-router-dom";
import Daily_summary from '../Pages/Daily_summary';
// {execiserId , fileName}

const ActionFrame = () => {

    const navigate = useHistory()
    const {execiserId} = useParams()

    const levelId= localStorage.getItem('levelId')
    const position= localStorage.getItem('position')
    const description= localStorage.getItem('description')
    const urlVideo = localStorage.getItem('urlVideo')
    const urlImage = localStorage.getItem('urlImage')
    const type = localStorage.getItem("type" , 0)




    if ((execiserId == null) || (urlVideo == null) || (levelId==null)||(urlImage == null))
    {
        //navgite
        // return navigate..
        navigate.goBack();
    }
  

    const [execiserIdBuild , setExeciserIdBuild] = useState(execiserId)
    const [videoUrl , setVideoUrl ] = useState(urlVideo)

    const [message , setMessage] = useState('')
    const [currentPage , setCurrentPage ] = useState(position)
    const [dataSummary , setDataSummary] = useState({})


    useEffect(()=>
    {
        console.log(currentPage)
        if(currentPage === 'Finish')
        {
            handleQuestSeconedNext()
        }
    } , [currentPage])

    // const handleQuestFirstNext = () =>
    // {
    //     setCurrentPage('video')
    // }
    // const handleVideoNext = () =>
    // {
    //     setCurrentPage('q2')
    // }
    const handleQuestSeconedNext = () =>
    {
        // setCurrentPage('summary')
        if(type == 1)
        {
            //navigate.goBack();
            navigate.replace('/Tutorilias')
        }
        else
        {  
            // localStorage.removeItem("levelId")
            localStorage.removeItem("urlImage")
            localStorage.removeItem("urlVideo")
            localStorage.removeItem('type')
            localStorage.removeItem('levelId')
            localStorage.removeItem('description')
            localStorage.removeItem('position')
            localStorage.removeItem('TimeVideo')
            localStorage.removeItem('success')

                navigate.replace('/Map')
                
        }
       

    }

      
  useEffect(() => {
    const unblock = navigate.block((location, action) => {
      if (action === 'POP') {
        return false; // Disable going back
      }
    });

    return () => unblock();
  }, [navigate]);

    return (

    <div className='h-full overflow-y-auto'>
      {currentPage === 'q1' && <PracticeVideos setCurrentPage = {setCurrentPage} step = {'q1'} levelId={levelId} execiserIdBuild = {execiserIdBuild}/>}
      {currentPage === 'video' && <VideoBig setCurrentPage = {setCurrentPage} description={description} videoUrl={urlVideo}   urlImage={urlImage} execiserIdBuild={execiserIdBuild}/>}
      {currentPage === 'q2' && <PracticeVideos setDataSummary = {setDataSummary} setCurrentPage = {setCurrentPage} step = {'q2'}  levelId={levelId} execiserIdBuild={execiserIdBuild} 
                                message= {message} setMessage = {setMessage}/>}
      {/* {currentPage === 'summary' && <summary message={message} />} */}
      {currentPage === 'summary' && <Daily_summary dataSummary = {dataSummary}/>}


    </div>


  )
}

export default ActionFrame