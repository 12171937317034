import React from 'react'
import { useRef ,  useState , useEffect} from "react"
import axiosAuth from '../Api/axiosAuth';
import axios from "axios";
import { Link } from "react-router-dom";
import  {useHistory , useLocation }  from "react-router-dom";
import useAuth from "../Hook/useAuth";
import Cookies from "universal-cookie"
import screen from "../Images/vectorSmartObject.png"
const LOGIN_URL = 'authenticate';
const Login = () => {
  // const userRef = useRef()
    const errRef = useRef()
    const navigate = useHistory()
    const loaction = useLocation()
    const from = loaction.state?.from?.pathname || "/Profile";
    const cookies = new Cookies();

    const [user ,setUser ] = useState('')
    const [pwd , setPwd] = useState('')
    const [errMsg , setErrMsg] = useState('')

    const { auth ,  setAuth } = useAuth()

    const initializeWonderPush = ({ userId }) => {
        var WonderPush = window.WonderPush || [];
        WonderPush.push(["init", {
          webKey: "f188f4b43b577de44c6df288ef21898ea1ac00445d1f2a9b3ff03284c9121af6",
        }]);
        window.WonderPush = window.WonderPush || [];
        WonderPush.push(['setUserId', userId]);
      };


      
    useEffect ( () => 
    {
        console.log("auth:" + auth)
        if(auth)
        {
            console.log("login")
            if(cookies.get('isProfilePassed') != null)
            {  
                if(cookies.get('groupType')!= null)
                {
                    if(cookies.get('groupType') == 'E')
                    {
                        navigate.replace("/Home");
                    }
                    else{
                        navigate.replace("/Map");
                    }
                }
             else{
                navigate.replace("/Home");
             }
            }
            else{
                navigate.replace("/Profile");
            }
        }
    
        // userRef.current.focus();
    } , [])
    // from , navigate , auth


    useEffect ( () => 
    {
        setErrMsg('')
    } , [user , pwd])


    const handleSubmit = async(e) => 
    {
        e.preventDefault()
        try
        {
            const response = await axiosAuth.post(LOGIN_URL , {
                'username': user, 'password': pwd
            }
             , {'Access-Control-Allow-Credentials': true});

             //initializeWonderPush(response?.data['wonderPush_token']);

             window.WonderPush = window.WonderPush || [];
             window.WonderPush.push(["init", {
               webKey: "f188f4b43b577de44c6df288ef21898ea1ac00445d1f2a9b3ff03284c9121af6",
             }]);
             window.WonderPush = window.WonderPush || [];
             window.WonderPush.push(['setUserId', response?.data['wonderPush_token']]);

            cookies.set('wonderPush_token' , response?.data['wonderPush_token'] ,  { path: '/'  , maxAge: 31536000} ,   {httpOnly: true}, {SameSite:"None"})
            cookies.set('bezkoder-jwt-refresh' , response?.data['refersh_token'] ,  { path: '/'  , maxAge: 31536000} ,   {httpOnly: true}, {SameSite:"None"})
        
            axios.defaults.headers.common['Authorization'] = `Bearer ${response?.data['access_token']}`;

          

            setAuth( response?.data['refersh_token'] )
            setPwd('')
            setUser('')
            if(cookies.get('isProfilePassed') != null)
            {  
               navigate.replace("/Home");
            }
            else{
                navigate.replace("/Profile");
            }

            if(response?.data['groupType'] == 'C' )
            {
                cookies.set('groupType' , 'C' ,  { path: '/'  , maxAge: 31536000} ,   {httpOnly: true}, {SameSite:"None"})
            }
            else{
                cookies.set('groupType' , 'E' ,  { path: '/'  , maxAge: 31536000} ,   {httpOnly: true}, {SameSite:"None"})
            }

        }
        catch(err)
        {
          
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if(err.response?.status === 404)
            {
                setErrMsg(`${err.response.data}`)
            }
            else {
                setErrMsg('Login Failed')
            }
            errRef.current.focus();
        }
        

    }
  return (
 
   
                
    <div className='h-screen  flex-col overflow-y-auto  flex justify-center items-center ' style={{backgroundImage:`url("${screen}")` 
    ,backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100vw',
    height: '100vh'}}>
    <p ref={errRef} className = {errMsg ? "alert alert-danger fw-bold mt-3" : "hide"} aria-live = "assertive"> {errMsg}</p>
    <div className='sm:w-[550px] max-sm:w-[70%]  max-h-96   rounded-lg grid p-3'>
    <form className="mt-2" onSubmit={handleSubmit}> 
        
        <div className='text-lg pr-2 font-semibold text-black text-right '> מספר חשבון</div>
        <input type="text"    className='w-full flex  bg-transparent text-right  border-b-black  border-b my-4' onChange={(e) => setUser((e.target.value).toLowerCase())}  value = {user}
                        required />
        
        <div className='text-lg pr-2 font-semibold  text-right '> סיסמה</div>
        <input type="password"  className='w-full bg-transparent flex text-right border-b-black  border-b  my-4 'onChange={(e) => setPwd(e.target.value)} 
        value = {pwd}
        required    />
        <div className='w-full grid'>
            <button className=' w-fit text-center  rounded-xl p-3 text-xl bg-[#394351] justify-self-center mt-4 text-white' >כניסה</button>
        </div>
        </form>
    </div>
    </div>
 
   )
}

export default Login