import React from 'react'
import bubble from "../Images/bubble.svg"
import { ReactComponent as ScaleH } from "../Images/scaleH.svg"
// import { ReactComponent as ScaleV } from "../Images/scaleV.svg"
import ScaleV from './ScaleV'

const QA_2 = () => {
  return (
    <div className='h-4/5 flex flex-col overflow-y-auto'>
    <div className='bg-midnight'>QA_2
        <div><img src={bubble} alt="" className='mx-6'/></div>
        <div className='h-2/4 m-6 p-3 flex flex-col bg-white rounded-lg' >
        <div className='w-full p-2 flex flex-row'> 
    <ScaleV/>
    <ScaleV/>
    <ScaleV/>
    </div>
            {/* <p>גרפעם</p>
            <div className='flex flex-row '>
            <ScaleV className="mx-2 h-44"/> */}
            {/* <ScaleV className="mx-2"/> */}
            {/* <ScaleV className="mx-2"/> */}
            {/* </div> */}
        </div>
        
        
        <div className='m-6 p-3 flex flex-col bg-white rounded-lg' >
            <p className='font-bold text-2xl'>תחושת רווחה</p>
            <div>

            </div>
                <ScaleH className="my-2"/>
            </div>
        <div className='flex justify-between mx-6 flex-row-reverse py-10 '>
            <button className='w-20 rounded-lg h-14 bg-tahiti'>סיימתי</button>
            <button className='w-20  rounded-lg bg-metal'>המשך</button>
        </div>
    </div>
    </div>
  )
}

export default QA_2