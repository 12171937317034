import React, { useEffect, useState } from 'react'
import useAuth from '../Hook/useAuth'
import  {useHistory , useLocation }  from "react-router-dom";
import Home_bubble_botoom from '../Components/Home_bubble_botoom';
import Home_bubble_up from '../Components/Home_bubble_up';
import MessageBubble from "../Images/Home/Home_bubble_up/MessageBubble.svg"
import axios from 'axios'
import { useQuery } from "react-query";
import Cookies from "universal-cookie"
const Home = () => {

  const cookies = new Cookies()
  const load = cookies.get('groupType'); 
  const [ groupType , setGroupType ]  = useState(load ? cookies.get('groupType') : null)


  const { auth , logout , setAuth } = useAuth()
  const navigate = useHistory()

  const { isLoading, isError, data, error, refetch } = useQuery(["my_home"], () =>
  axios.get(`function_patient/my_home` ,
  { 
  headers: { 'Content-Type': 'application/json'}
  } ,
  {'Access-Control-Allow-Credentials': true}) )

 if (isLoading) 
 {
  return(
    <div className='h-screen flex flex-col overflow-y-auto 'style={{background: "linear-gradient(0deg, #F0F0F0 0.06%, #F4F4F4 82.66%, #F6F6F6 100%)"}}>
    </div>
    )
 }

if (error) 
{
  if(!auth)
  {
    navigate.replace('/Login')
  }
  return "An error has occurred: " + error.message;
} 
 console.log(data?.data)
 const intHome  = data?.data

  return (
        <div className='h-full flex flex-col overflow-y-auto 'style={{background: "linear-gradient(0deg, #F0F0F0 0.06%, #F4F4F4 82.66%, #F6F6F6 100%)"}}>
           <div className='p-2 pb-0 relative'>
           <img src={MessageBubble} alt="" />
            
            <div className='w-[95%] absolute top-[20%] flex justify-center '> 
              <p className='font-Hebbo font-medium text-black text-right' style={{fontSize:"5vw"}}>שלום {intHome?.nickName}</p>
            </div>
            </div>
           {groupType !== 'C' && <Home_bubble_up attributeMyLevel={intHome?.attributeMyLevel} attributeCompleteLevel={intHome?.attributeCompleteLevel}/>}
            <Home_bubble_botoom attributeCompleteDailyPractice={intHome?.attributeCompleteDailyPractice} index={intHome?.attributeMyLevel?.levelId}/> 
        </div>
  )
}

export default Home