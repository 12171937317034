import React from 'react'

const ScaleV_Daily_summary = ({title,deltawell_being ,Q1,Q2}) => {
  const min = Math.min(Q1,Q2)
    return (
    <div className='flex flex-col w-full px-4'>
        
        
        <text className='font-Hebbo font-semibold text-center' style={{fontSize:"6vw"}}> {title}</text>
        
        
        
        <div>
            <div className="h-[5vw]    bg-gray-300 relative rounded-lg ">
                <div className={`h-full  ${deltawell_being<0?"bg-red-600":"bg-green-600"} absolute `} style={{width:`${Math.abs(deltawell_being*9)+2}%`,left:`${(min)*9+3}%`}}></div>
            </div>
            <div className=' flex flex-row-reverse justify-between px-2 w-full '>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>10</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>9</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>8</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>7</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>6</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>5</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>4</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>3</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>2</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>1</p>
            <p className='font-Hebbo' style={{fontSize:"4vw"}}>0</p>
            </div>
        </div>
        
        </div>
  )
}

export default ScaleV_Daily_summary