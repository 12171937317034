import React from 'react'
import Negative_feedback from './Feedback/Negative_feedback'
import ScaleH_Daily_summary from './ScaleH_Daily_summary'
import ScaleV_Daily_summary from './ScaleV_Daily_summary'
const Graph_metrics = ({session_finish,textRule}) => {

  const deltawell_being=(session_finish?.well_beingQ2-session_finish?.well_beingQ1)
  const deltafatigue=(session_finish?.fatigueQ1-session_finish?.fatigueQ2)
  const deltavas=(session_finish?.vasQ1-session_finish?.vasQ2)
  const deltasuds=(session_finish?.sudsQ1-session_finish?.sudsQ2)
  console.log(deltawell_being)
  // const vas=()
  // const suds=()
  return (
    <div className=' bg-white flex flex-col rounded-2xl my-4 p-2'>
        <p className='font-Hebbo font-medium text-right'style={{fontSize:"6vw"}}>:מדדים   </p>
        <Negative_feedback textRule={textRule}deltawell_being={deltawell_being} deltafatigue={deltafatigue} deltavas={deltavas} deltasuds={deltasuds} />
        <ScaleV_Daily_summary title={"רווחה נפשית"} deltawell_being={deltawell_being} Q1={session_finish?.well_beingQ1} Q2={session_finish?.well_beingQ2}/>
        
        <div className='w-full flex flex-row justify-between py-6 px-3 border-b-2' style={{borderImage: "linear-gradient(45deg, rgba(220, 226, 127, 1), rgba(211, 221, 123, 1), rgba(186, 208, 112, 1), rgba(147, 190, 94, 1), rgba(89, 168, 76, 1), rgba(51, 160, 72, 1)) 1 "}}> 
        <ScaleH_Daily_summary title={"כאב"} high={session_finish?.vasQ1} low={session_finish?.vasQ2}/>
        <ScaleH_Daily_summary title={"דחק"} high={session_finish?.sudsQ1} low={session_finish?.sudsQ2}/>
        <ScaleH_Daily_summary title={"תשישות"} high={session_finish?.fatigueQ1} low={session_finish?.fatigueQ2}/>
        </div>
        <p className='font-Hebbo font-medium text-right'style={{fontSize:"6vw"}}>: מדדים שהשתפרו  </p>
        <div className='w-full '>
            <div className="w-full flex flex-wrap justify-end ">
               {deltafatigue>0?<text className='w-fit rounded-lg mx-2 p-2 bg-gray-300'style={{fontSize:"5vw"}} >תשישות</text>:""}
                {deltasuds>0?<text className='w-fit rounded-lg mx-2 p-2 bg-gray-300'style={{fontSize:"5vw"}}>דחק</text>:""}
                {deltavas>0?<text className='w-fit rounded-lg mx-2 p-2  bg-gray-300' style={{fontSize:"5vw"}}>כאב</text>:""}
                {deltawell_being>0?<text className='w-fit rounded-lg mx-2 p-2  bg-gray-300'style={{fontSize:"5vw"}}>רווחה נפשית</text>:""}
               
               
            </div>
              
    </div>
        <p className='font-Hebbo font-medium text-right'style={{fontSize:"6vw"}}>: מדדים שלא השתפרו  </p>
        <div className='w-full '>
            <div className="w-full flex flex-wrap justify-end ">
               {deltafatigue<=0?<text className='w-fit rounded-lg mx-2 p-2  bg-gray-300' style={{fontSize:"5vw"}} >תשישות</text>:""}
                {deltasuds<=0?<text className='w-fit rounded-lg mx-2 p-2  bg-gray-300' style={{fontSize:"5vw"}}>דחק</text>:""}
                {deltavas<=0?<text className='w-fit rounded-lg mx-2 p-2  bg-gray-300' style={{fontSize:"5vw"}} >כאב</text>:""}
                {deltawell_being<=0?<text className='w-fit rounded-lg mx-2 p-2  bg-gray-300' style={{fontSize:"5vw"}}>רווחה נפשית</text>:""}
               
               
            </div>
              
    </div>
    </div>
  )
}

export default Graph_metrics