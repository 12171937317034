import React from 'react'
import back from "../Images/OpenScreenBG.svg"
import screen from "../Images/vectorSmartObject.png"
import {ReactComponent as Openscreenlogo} from "../Images/OpenScreenLogo.svg"

const OpenScreen = () => {
  return (
    <div className="h-screen "style={{position: "relative"}}>
    <img src={screen} alt="sda" style={{width:"100%",height:"100%"}}/>
    <Openscreenlogo style={{    height: "40%",
    width: "40%",
    position: "absolute",
    top: "30%",
    left: "33%"
}}/>
    </div>
    // <div style={{width:"100%",minHeight:"1000px",backgroundImage:`url(${back})`,backgroundPosition:"center",position:"relative"}}>OpenScreen</div>
  )
}

export default OpenScreen