import React, { useEffect } from 'react'
import top_bubble from "../Images/Daily summary/top_bubble1.svg"
import Daily_summary_List_Tutorilias from '../Components/Daily_summary/Daily_summary_List_Tutorilias'
import Graph_metrics from '../Components/Daily_summary/Graph_metrics'
const Daily_summary = ({dataSummary}) => {


    useEffect(()=>
    {
      console.log(dataSummary)

    } ,[])


  return (
    <div className='px-4 pb-2'style={{background:"linear-gradient(to bottom,rgba(244, 244, 244, 1), rgba(240, 240, 240, 1))"}}>
        <p className=' font-bold font-Hebbo text-center'style={{fontSize:"7vw"}} >סיכום פעילות</p>
        <img className="w-full my-2" src={top_bubble} alt="" />
        <Daily_summary_List_Tutorilias exercisesSession = { dataSummary?.exercisesSession} />
        <Graph_metrics session_finish = {dataSummary?.session_finish} textRule={dataSummary?.textRule}/>
     
    </div>
  )
}

export default Daily_summary