import React from 'react'
import Video1 from "../Images/Videos/stage1_videoCover_list.svg"
import Video2 from "../Images/Videos/stage2_videoCover_list.svg"
import Video3 from "../Images/Videos/stage3_videoCover_list.svg"
import Video4 from "../Images/Videos/stage4_videoCover_list.svg"
import Video5 from "../Images/Videos/stage5_videoCover_list.svg"
import { Link } from 'react-router-dom'
import  {useHistory , useLocation }  from "react-router-dom";
import axios from 'axios'


const Video = ({levelId,time,discrption,actionId,urlImage}) => {
const navigate = useHistory()
const arrVioeds={1:Video1,
  2:Video2
// "linear-gradient(73.19deg, #09090B 0.05%, #F1872D 0.13%, #4995CF 0.56%, #67509C 97.25%)",
  ,3:Video3
  // "linear-gradient(73.19deg, #5DBC9C 0.15%, #3F9DD6 97.28%)",
  ,4:Video4
  // "linear-gradient(73.19deg, #F1872D 0.13%, #E81776 97.26%)"
  ,5:Video5
  // "linear-gradient(73.19deg, #FFCA3B 0.05%, #F37C47 97.47%)"}}
}

  const actionFrameFun = async(e) =>{

    // we need create a exirces.. 
    e.preventDefault()
    await axios.post('function_patient/build_exercise' , 
    JSON.stringify({actionId : actionId }) , 
    { 
      headers: { 'Content-Type': 'application/json'}
    } ,
    {'Access-Control-Allow-Credentials': true})
      .then((res) => {
      if(res?.status === 200)
       {
            console.log("actionId:" +  actionId)
            console.log(res?.data)

            const execiserId = res?.data?.exercise_id
            const urlVideo =  res?.data?.urlVideo
            const urlImage =  res?.data?.urlImage
            const description =  res?.data?.description

            if((execiserId != null)&&(urlVideo!= null)&&(urlImage!=null))
            {
              localStorage.setItem('urlVideo' ,urlVideo)
              localStorage.setItem('urlImage' ,urlImage)
              localStorage.setItem('type' , 2) 
              localStorage.setItem('levelId' , levelId) 
              localStorage.setItem('description' , description) 
              localStorage.setItem('position' ,"q1")
              localStorage.setItem('execiserId' ,execiserId)
              navigate.push(`/ActionFrame/${execiserId}`);
            }
       }
       else{
          if(res.response?.status === 500)
           {
              console.log(res?.data)
           }
       }
    })
      .catch( (err) => 
          {
            
            // if(err.response?.status === 401)
            // {
            //   logoutFun()
            // }
          })
  }
  return (
    <div className='flex flex-col items-end  w-40  m-2'>
  

  <Link className="bg-no-repeat bg-cover rounded-xl" style={{backgroundImage:`url("${urlImage}")`}} to="/VideoBig">
    <img className='w-full rounded-xl' src={arrVioeds[levelId]} alt="asd" 
   /* <img className='w-screen ' src={"https://imgsvideos.s3.eu-central-1.amazonaws.com/My safe place.jpg"} alt="asd"  */
    onClick={actionFrameFun}
  /></Link>
  <div className='flex '>
            <p className='pr-1'>
               דקות
            </p>
            <p className='text-lg font-light font-Hebbo pr-1'>
                {`${time}`}
            </p>
            
            </div>

            <p className='w-40 text-lg font-medium  font-Hebbo text-right'>
                {discrption}
            </p>




    </div>
  )
}

export default Video