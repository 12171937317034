import React from 'react'
import bubble from '../Images/bubble.svg'

const Popup = ({setCurrentPage,message}) => {
  // localStorage.setItem('position' ,"video")
  return (
    <div id="popup" className='h-full w-full absolute top-0  left-0  bg-[rgba(49,49,49,0.8)] flex justify-center items-center flex-col'>
        <div className='bg-white w-[70%] rounded-xl '>
        {/* <div id="overlay" className='rounded-xl w-[100%] border border-b-transparent p-3 relative flex flex-col items-center  ' style={{background:"linear-gradient(90deg, rgba(231, 33, 68, 0.05), rgba(118, 84, 158, 0.05))"}}> */}
        <div id="overlay" className='rounded-xl w-[100%] border border-b-transparent p-3 relative flex flex-col items-center bg-red-50 '>
        
           {/* <img src={bubble} alt="" className="h-[30vh]" /> */}
          
            <div className='w-[100%] p-2  ' > 
              <p className=' w-full font-Hebbo font-medium text-black text-center whitespace-pre-line ' style={{direction: "rtl"}} > 
              {message}</p>
            </div>
            <button className='p-3  rounded-lg' style={{background:"linear-gradient(90deg, rgba(231, 33, 68, 1), rgba(118, 84, 158, 1))"}}onClick={() => {
               localStorage.setItem('TimeVideo' ,0)
               localStorage.setItem('success' ,false)
               localStorage.setItem('position' ,"video")
              setCurrentPage("video")}
              }>   אני רוצה להמשיך</button>
            {/* <div className='w-[40px] h-[40px] bg-white absolute top-[92%]  border-transparent       rotate-45 ;'></div> */}
            
            <div className=' absolute top-[98%] border-r-transparent border-r-[30px]  border-l-[30px] border-t-[22px]  border-l-transparent  border-t-red-50' ></div>

        </div>
        </div>
           
       
    </div>
  )
}

export default Popup