import React, { useState } from 'react'
import pointScale from "../Images/PointScale.svg"
import smile0 from "../Images/Smiles/0.svg"
import smile1 from "../Images/Smiles/1.svg"
import smile2 from "../Images/Smiles/2.svg"
import smile3 from "../Images/Smiles/3.svg"
import smile4 from "../Images/Smiles/4.svg"
import smile5 from "../Images/Smiles/5.svg"
import smile6 from "../Images/Smiles/6.svg"
import smile7 from "../Images/Smiles/7.svg"
import smile8 from "../Images/Smiles/8.svg"
import smile9 from "../Images/Smiles/9.svg"
import smile10 from "../Images/Smiles/10.svg"

const BarQaH = ({col,setcol}) => {
    // const [col,setcol]=useState(-1)
    return (
    <div className=" w-full h-full  flex flex-col items-center ">
    {/* <div className='w-full'>{title}</div> */}

    <div className=" w-[90%] h-full  flex flex-col justify-center ">
            <div className=" w-[100%] h-[2vh] flex flex-row items-center justify-between rounded-[52px]" style={{background:"linear-gradient(270deg,rgba(50, 166, 0, 1), rgba(235, 255, 0, 1), rgba(255, 194, 85, 1), rgba(255, 0, 64, 1), rgba(215, 26, 64, 1))"}}>
                <button className=" flex justify-start items-center h-[4vh] w-[4vh] relative" onClick={()=>{setcol(0)}}>{col==0?<img src={smile10} className='absolute  h-full'/>:""}<img src={pointScale} className=' w-[2vh]'/> </button>
                <button className=" flex justify-center items-center h-[4vh] w-[4vh] relative" onClick={()=>{setcol(1)}}>{col==1?<img src={smile9} className='absolute h-full'/>:<img src={pointScale} className='w-[2vh]'/>}</button>
                <button className=" flex justify-center items-center h-[4vh] w-[4vh] relative" onClick={()=>{setcol(2)}}>{col==2?<img src={smile8} className='absolute h-full'/>:""}<img src={pointScale} className='w-[2vh]'/> </button>
                <button className=" flex justify-center items-center h-[4vh] w-[4vh] relative" onClick={()=>{setcol(3)}}>{col==3?<img src={smile7} className='absolute h-full'/>:""}<img src={pointScale} className='w-[2vh]'/></button>
                <button className=" flex justify-center items-center h-[4vh] w-[4vh] relative" onClick={()=>{setcol(4)}}>{col==4?<img src={smile6} className='absolute h-full'/>:""}<img src={pointScale} className='w-[2vh]'/> </button>
                <button className=" flex justify-center items-center h-[4vh] w-[4vh] relative" onClick={()=>{setcol(5)}}>{col==5?<img src={smile5} className='absolute h-full'/>:""}<img src={pointScale} className='w-[2vh]'/> </button>
                <button className=" flex justify-center items-center h-[4vh] w-[4vh] relative" onClick={()=>{setcol(6)}}>{col==6?<img src={smile4} className='absolute h-full'/>:""}<img src={pointScale} className='w-[2vh]'/> </button>
                <button className=" flex justify-center items-center h-[4vh] w-[4vh] relative" onClick={()=>{setcol(7)}}>{col==7?<img src={smile3} className='absolute h-full'/>:""}<img src={pointScale} className='w-[2vh]'/> </button>
                <button className=" flex justify-center items-center h-[4vh] w-[4vh] relative" onClick={()=>{setcol(8)}}>{col==8?<img src={smile2} className='absolute h-full'/>:""}<img src={pointScale} className='w-[2vh]'/> </button>
                <button className=" flex justify-center items-center h-[4vh] w-[4vh] relative" onClick={()=>{setcol(9)}}>{col==9?<img src={smile1} className='absolute h-full'/>:""}<img src={pointScale} className='w-[2vh]'/></button>
                <button className=" flex justify-end items-center h-[4vh] w-[4vh] relative" onClick={()=>{setcol(10)}}>{col==10?<img src={smile0} className='absolute W-full'/>:""}<img src={pointScale} className='w-[2vh]'/> </button>
                

            </div>
            <div className="w-[full] flex flex-row-reverse justify-between pl-1 ">
                <p className='font-Hebbo '>10</p>
                <p className='font-Hebbo '>9</p>
                <p className='font-Hebbo '>8</p>
                <p className='font-Hebbo '>7</p>
                <p className='font-Hebbo '>6</p>
                <p className='font-Hebbo '>5</p>
                <p className='font-Hebbo '>4</p>
                <p className='font-Hebbo '>3</p>
                <p className='font-Hebbo '>2</p>
                <p className='font-Hebbo '>1</p>
                <p className='font-Hebbo mr-1'>0</p>
            </div>

    </div>
    </div>
  )
}

export default BarQaH